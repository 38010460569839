import React, { useState, createContext, useContext, useEffect } from 'react'
import { getListRank } from '../actions/memberInfo'
import {
    getListAvailableRanks,
    checkHadCreditCard, getSelectionState
} from '../functions'

export const RankContext = createContext()

export const useRank = () => useContext(RankContext)

const userDefaultData = {
    accountId: "000000000",
    accountName: '',
    rankId: '',
    rankName: '',
    startDate: null,
    expDate: null,
    differenceGold: null,
    differenceSilver: null,
    totalPurchasePrice: null
}

const paymentDefaultData = {
    point: false,
    creditCard: {
        // registered: checkHadCreditCard(),
        usable: false
    },
    payPay: false
}

const RankContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [userInfo, setUserInfo] = useState(userDefaultData)
    const [listRanks, setListRanks] = useState([])
    // Rank selected in list options
    const selectionState = getSelectionState()
    const [selectedRank, setSelectedRank] = useState(
        selectionState?.rank
    )
    const [openMdRejoinPrevRank, setOpenMdRejoinPrevRank] = useState(false)
    const [pointBalance, setPointBalance] = useState(
        selectionState?.points ?? 0
    )
    const [paymentMethods, setPaymentMethods] = useState(
        selectionState?.method ?? paymentDefaultData
    )

    useEffect(() => {
        return () => setErrorMsg('')
    }, [])

    const resetContext = () => {
        setUserInfo(userDefaultData)
        setSelectedRank(null)
        // setListRanks([])
        setPointBalance(0)
        setPaymentMethods(paymentDefaultData)
    }

    // Fetch and filter list ranks
    const fetchListRanks = (currentRankId = '') => {
        setIsLoading(true)

        const handleDataRpGetListRank = res => {
            if (res?.status === 200 && res?.data?.success) {
                const { account_rank_group_list: listRanksAPI } = res?.data

                setErrorMsg('')
                setListRanks(currentRankId
                    ? getListAvailableRanks(currentRankId, listRanksAPI)
                    : listRanksAPI
                )
            }
            else
                setErrorMsg(res?.data?.message)

            setIsLoading(false)
        }

        getListRank(handleDataRpGetListRank);
    }

    return (
        <RankContext.Provider
            value={{
                userInfo, setUserInfo,
                isLoading, setIsLoading,
                errorMsg, setErrorMsg,
                selectedRank, setSelectedRank,
                listRanks, setListRanks,
                resetContext,
                fetchListRanks,
                openMdRejoinPrevRank, setOpenMdRejoinPrevRank,
                pointBalance, setPointBalance,
                paymentMethods, setPaymentMethods
            }}
        >
            {children}
        </RankContext.Provider>
    )
}

export default RankContextProvider

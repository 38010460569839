import { useLocation, useNavigate } from "react-router-dom";
import { HOME_SCREEN, REGISTER_SCREEN } from "../../constants/path"
import { useLastLocation } from "./LastLocationContext";

const PUBLIC_ALLOWED_ROUTES = [REGISTER_SCREEN, `/${HOME_SCREEN}`]

export function useInvalidUrlAccess(isMounted) {
    const currentLocation = useLocation()
    const navigate = useNavigate()

    const { lastVisitedLocation } = useLastLocation();

    if (isMounted &&
        !lastVisitedLocation.current &&
        !PUBLIC_ALLOWED_ROUTES.includes(currentLocation.pathname)
    ) {
        navigate(`/${HOME_SCREEN}`, { replace: true })
    }
}


// Reference of ideas and source code:
// https://stackoverflow.com/questions/60939736/prevent-react-router-from-accessing-directly-an-url-without-navigating
import React, { useState, useEffect } from 'react'
import {
    useSearchParams, useNavigate, useLocation
} from "react-router-dom"
import { FiCheck } from 'react-icons/fi'
import { Divider } from 'antd'

import { LcStorage } from '../../../utils/storage'
import { IconStarBland, IconStarFilled } from '../../../assets/img'
import stringJp from '../../../constants/string'
import {
    ACTION, AGREE_POLICY, IS_SUCCESS, PAYPAY_ORDER_ID
} from '../../../constants'

import {
    formatDate, getAdmissionFee, getRankImgSrcSuccess,
    getRankNameSuccess, getScreenOrModalTitle,
    getSelectionState, getCompanyCodeLocal,
} from '../../../contexts/functions'
import { useRank } from '../../../contexts/ranks/RankContext'
import { useLastLocation } from '../../../contexts/location/LastLocationContext'
import { getMemberRanksScreenSuccess } from '../../../contexts/actions/memberInfo'
import { HOME_SCREEN, REGISTER_SCREEN } from '../../../constants/path'
import { COMPANY_CODES } from '../../../constants/configCompany'
import MainLayout from '../../../layout/MainLayout'
import { StyledButton } from '../../../components'
import './indexRegistrationSuccess.scss'

const PUBLIC_ALLOWED_ROUTES = [REGISTER_SCREEN, `/${HOME_SCREEN}`]

function RegistrationSuccess() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const currentLocation = useLocation()
    const { pathname, search: queryParams } = currentLocation
    const { lastVisitedLocation } = useLastLocation()
    const { resetContext } = useRank()
    const [rankData, setRankData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [openMdError, setOpenMdError] = useState(false)
    const selectionState = getSelectionState()
    const isSuccess = +LcStorage.getLocalJson(IS_SUCCESS) === 1

    useEffect(() => {
        if (!isSuccess &&
            !PUBLIC_ALLOWED_ROUTES.includes(pathname) &&
            (!lastVisitedLocation.current || !isSuccess)
        ) {
            navigate(`/${HOME_SCREEN}`, { replace: true })
            return
        }
        LcStorage.removeLocal(AGREE_POLICY);
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 10000);
        getMemberRanksScreenSuccess(handleDataRpGetRank)
    }, [queryParams])

    const handleDataRpGetRank = res => {
        const { success, rank_id, rank_name, rank_expiration_date } = res?.data
        setIsLoading(false)
        if (res?.status === 200 && success) {
            const registeredRank = selectionState?.rank
            setRankData({
                id: rank_id,
                name: rank_name,
                expDate: rank_expiration_date,
                admissionFee: registeredRank?.admission_fee,
            })
        }
        else setOpenMdError(true)
    }

    const handleExit = () => {
        resetContext()
        // LcStorage.removeLocal(SELECTION_STATE)
        LcStorage.removeLocal(PAYPAY_ORDER_ID)
        // Exit Loyalty webview. Redirect to app Scan&Go
        window.location.assign(process.env.REACT_APP_RANKS_DEEP_LINK)
    }

    const rankName = isUpper =>
        getCompanyCodeLocal() === COMPANY_CODES.KASUMI
            ? getRankNameSuccess(rankData?.id, '', isUpper)
            : (<span>{rankData?.name}</span>)

    const screenTitle = getScreenOrModalTitle(
        searchParams.get(ACTION) || selectionState?.action,
        true
    )

    return (
        <MainLayout className="payment-success-screen"
            screenTitle={screenTitle}
            color={'#ffffff'}
            styleHeader={{ backgroundColor: '#5EBBD8' }}
            isLoading={isLoading}
            openMdError={openMdError}
            setOpenMdError={setOpenMdError}
            errorMsg={stringJp.err_get_member_info}
        >
            <div className="screen-body-wrapper">
                <div className="result-frame">
                    <div className="icon-status-container">
                        <div className="icon-status-circle">
                            <FiCheck className="icon-status" />
                        </div>
                        <img src={IconStarFilled} alt='' className='star-filled' />
                        <img src={IconStarBland} alt='' className='star-bland-top' />
                        <img src={IconStarBland} alt='' className='star-bland-bottom' />
                    </div>

                    <div className="result-content">
                        <p className="msg-completed">
                            {rankName(false)? rankName(false) : ''}{' '}
                            {stringJp.msg_registered_completed}
                        </p>
                        <div className="background-card-rank">
                            <img src={getRankImgSrcSuccess(rankData?.id)}
                                alt="card-rank" width="100%" height="100%"
                            />
                        </div>

                        <>
                            <Divider className='divider' />

                            <div className="txt-content">
                                <p className='text_title_info_rank'>{stringJp.txt_rank}</p>
                                <p>{rankName(true)? rankName(true) : ''}</p>
                            </div>

                            <Divider className='divider' />

                            <div className="txt-content">
                                <p className='text_title_info_rank'>{stringJp.txt_membership_fee}</p>
                                <p>{getAdmissionFee(rankData?.admissionFee)? getAdmissionFee(rankData?.admissionFee) : '' }</p>
                            </div>

                            <Divider className='divider' />

                            <div className="txt-content">
                                <p className='text_title_info_rank'>{stringJp.txt_expired_date}</p>
                                <p>{formatDate(rankData?.expDate, 'LLL') ? formatDate(rankData?.expDate, 'LLL') : ''}</p>
                            </div>

                            <Divider className='divider' />
                        </>
                    </div>
                </div>
            </div>

            <div className='screen-footer-wrapper'>
                <StyledButton
                    label={stringJp.title_btn_back_to_SnG_app}
                    onClick={handleExit}
                />
            </div>
        </MainLayout>
    )
}

export default RegistrationSuccess


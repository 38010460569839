const COMPANY_CODES = {
    KASUMI: '100',
    MARUETSU: '200',
    MAX_VALUE: '300'
}

// Configs for specific company
const company = {
    //==================== Common ====================
    PHONE_NUMBER: '0120065723',
    EMAIL_CONTACT_URL: 'https://ignica.com/apps/scan_and_go/info/index.html',

    //==================== Specific ===================
    // company_code = 100
    [COMPANY_CODES.KASUMI]: {
        // URL of iframe in pop-up "Confirm Out Rank"
        OUT_RANK_GOLD_URL: '/page_renders/gold.html',
        OUT_RANK_SILVER_URL: '/page_renders/silver.html',
        OUT_RANK_BRONZE_URL: '/page_renders/bronze.html',

        // Policy
        PDF_URL: '/pdf/pdf_100.pdf',

        // Rank's id & name
        RANK: {
            GOLD: 'BLANDE GOLD',
            SILVER: 'BLANDE SILVER',
            BRONZE: 'BLANDE BRONZE'
        },
        RANK_ID: {
            GOLD: '1',
            SILVER: '2',
            BRONZE: '3'
        },
        RANK_FULL_NAME_UPPER: {
            GOLD: 'BLANDE Prime GOLD',
            SILVER: 'BLANDE Prime SILVER',
            BRONZE: 'BLANDE Prime BRONZE'
        },
        RANK_FULL_NAME_LOWER: {
            GOLD: 'BLANDE Prime Gold',
            SILVER: 'BLANDE Prime Silver',
            BRONZE: 'BLANDE Prime Bronze'
        },
    },

    // company_code = 300
    [COMPANY_CODES.MAX_VALUE]: {
        // URL of iframe in pop-up "Confirm Out Rank"
        OUT_RANK_URL: 'https://www.mv-kanto.co.jp/prime/',

        // Policy
        PDF_URL: '/pdf/pdf_300.pdf',

        RANK_ID: {
            MAX_VALUE: '4'
        },
    },

    // company_code = 200
    [COMPANY_CODES.MARUETSU]: {
        // URL of iframe in pop-up "Confirm Out Rank"
        OUT_RANK_URL: 'https://www.maruetsu.co.jp/prime/', 

        // Policy
        PDF_URL: '/pdf/pdf_200.pdf',

        RANK_ID: {
            MARUETSU: '5'
        },
    }


}

const kasumiCompany = company[`${COMPANY_CODES.KASUMI}`]
const maxValueCompany = company[`${COMPANY_CODES.MAX_VALUE}`]
const maruetsuCompany = company[`${COMPANY_CODES.MARUETSU}`]

export {
    COMPANY_CODES,
    company,
    kasumiCompany,
    maxValueCompany,
    maruetsuCompany
}
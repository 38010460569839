import { COMPANY_CODES } from "./configCompany";

const stringJp = {
    // -------------------------Common-------------------------
    // Screen & Modal titles
    title_register_rank: 'ランク登録',
    title_change_rank: 'ランク変更',
    title_renewal_rank: 'ランク更新',

    // Label of buttons 
    btn_agree: '確認',
    title_btn_exit: '閉じる',
    title_btn_confirm: '確定',
    text_button_cancel: 'キャンセル',
    title_btn_contact_via_call: '電話で問い合わせる',
    title_btn_contact_via_email: 'メールで問い合わせる',
    title_btn_back_to_SnG_app: 'Scan&Goアプリに戻る',

    // Texts/labels
    txt_membership_fee: '入会費',
    txt_free: '無料',
    txt_yen: '円',
    txt_symbol_yen: '¥',
    txt_sama: '様',

    // Message "Contact call center"
    msg_failure_ask_call_center: 'コールセンターにお問い合わせ',
    msg_kudasai: 'ください。',
    // ---------------------------------------------------------


    // ____________________"Register" screen____________________
    // 1. Phone number
    title_header_register_screen: 'SMS認証',
    title_screen_send_phone_number: 'Scan&Go Ignicaアプリに登録している電話番号を入力して下さい。',
    title_btn_send_phone: 'SMSを送信',

    // 2. SMS OTP
    title_screen_send_sms_code_1: '受信したSMS（メッセージ）の中にある6桁の認証コードを入力し、',
    title_screen_send_sms_code_2: '[認証］ボタンをタッチしてください。',
    msg_send_sms_code: '　認証コードの有効期限は10分間です。10分以内に操作を行ってください。',
    msg_has_not_received_sms_code: '認証コードが届かなかった場合、',
    btn_link_resend_sms_code: '再送する',
    title_btn_send_sms_code: '認証',
    msg_resent_done: '認証コードを再送しました',

    // ____________________"Home" screen____________________
    title_home_screen: '会員情報',
    txt_id: 'ID: ',
    msg_regis_rank_row_1a: 'ランク',
    msg_regis_rank_row_1b: '未登録',
    msg_regis_rank_row_1c: 'です。',
    // title_btn_register_rank: '会員ランク登録',

    title_btn_call: '電話',
    txt_accept_call_time: '（受付時間：9:00~21:00）',
    title_btn_email: 'メール',

    msg_warning_expiration_1a: 'ご利用中の会員資格はまもなく',
    msg_warning_expiration_1b: '有効期限切れ',
    msg_warning_expiration_1c: 'となります。ランクの更新をお願いいたします。',
    msg_expiration: '※有効期限 ：',

    // ______________________"Selection" screen______________________
    txt_tax_included: ' (税込)',
    txt_selection_message: '入会を希望するランクを選択してください。',

    // ____________________"Policy" screen____________________
    title_policy: '会員規約',
    txt_policy_agreement: '上記利用規約に同意する',

    // ____________________"Payment" screen____________________
    txt_annual_fee: '年会費',
    txt_point: 'PT',
    txt_total: '合計',
    txt_points_using: 'ポイント利用',
    txt_not_use_point: '利用しない',
    txt_total_payment: 'お支払合計',
    txt_points_balance: 'ポイント残高',
    txt_equivalent_to_yen: '(円相当)',
    title_btn_pay_by_point: 'ポイント支払',
    title_btn_pay_by_card: 'クレジットカード',
    title_btn_pay_by_PayPay: 'PayPay',
    radio_btn_not_use_point: 'ポイントを使わない',
    radio_btn_use_points: 'ポイントを使う',
    radio_btn_use_all_points: 'すべてポイントでお支払い',
    err_pay_by_credit_card: 'クレジットカード情報が登録されていません。',

    // ________________"Payment success" screen________________
    title_screen_payment_success: '完了',
    msg_registered_completed: 'の登録が完了しました。',
    txt_rank: 'ランク',
    txt_expired_date: '有効期間終了日',

    // ____________________"Contact" screen____________________
    title_screen_contact: 'お問い合わせ',

    // ____________________"Error" screen____________________
    error_message_browser_back: 'エラーが発生しました。',

    // _________________"404 Not Found" screen_________________
    msg_page_not_found: 'お探しのページは見つかりません',

    // ___________________Error when call API___________________
    err_get_list_ranks: 'ランクグループ一覧を取得できません。',
    err_get_member_info: 'ランク情報を取得できません。',
    err_check_payment_method: '支払い方法を取得できません。',

    // --------------------------- Modals ---------------------------
    // _________"Check credit card (phone num screen)" modal_________
    title_credit_card_status: 'お支払いの確認',
    title_checkbox: '次回から表示しない',
    msg_credit_card_status_1: 'クレジットカード情報が未登録です。',
    msg_credit_card_status_2: 'お支払い方法を選択してください。',
    btn_PayPay_or_point: 'ポイントで支払う',
    btn_register_credit_card: 'クレジットカードを登録する',

    // ____________________"Out rank" modal____________________
    // >> Step 1
    title_modal_warning_out_rank: "退会手続き",
    msg_warning_out_rank_2: '退会により以下の対応が実施されます。',
    msg_warning_out_rank_3a: '既にお支払いいただいている年会費の日割り返金は行われません。',
    msg_warning_out_rank_3b: '退会処理完了後、本サービスの利用に関する一切の権利を失います。',

    txt_out_rank_agreement: '上記内容に同意する',

    // >> Step 2
    title_modal_confirm_out_rank: "退会の確認",
    cf_out_rank_mes_1: 'を退会します。',
    cf_out_rank_mes_2: 'よろしいですか？',
    cf_out_rank_mes_3: '会員様には',
    cf_out_rank_mes_4: '以下特典をご利用しております。',
    text_button_out_rank: '退会',

    // >> Step 3
    cf_out_rank_mes_5: '本当によろしいですか？',


    // >> Step 4 (success)
    title_modal_success_out_rank: "退会完了",
    text_userId: 'アカウントID：',
    msg_out_rank_success_1: 'を退会しました。',
    msg_out_rank_success_2: 'ご利用ありがとうございました。',

    // >> Step 4 (failed)
    title_modal_err_out_rank: "退会エラー",
    msg_out_rank_fail: '退会時にエラーが発生しました。',

    // ___________________"Duplicate rank" modal___________________
    title_md_rejoin_rank_warning: 'ランク選択エラー',
    txt_md_rejoin_rank_content_1: `］に基づき、`,
    txt_md_rejoin_rank_content_2: `お客様のアカウントは`,
    txt_md_rejoin_rank_content_3: `このランクにご登録いただけません。`,
    txt_md_rejoin_rank_content_4: `詳細はignicaコールセンター`,
    txt_md_rejoin_rank_content_5: `までお問い合わせください。`,

    // ____________________"Up/down rank" modal____________________
    title_md_up_down_rank: 'ランク変更注意',
    msg_warning_up_down: '注意！！',
    chk_agree_up_down: '上記に同意する',

    // Content was updated on August 01, 2022
    txt_md_change_rank_1: '第6条　会員プラン変更',
    txt_md_change_rank_2: 'また、退会手続き時、既にお支払いいただいた年会費の日割り返金は行わないものとします。',
    txt_md_change_silver_to_gold_1: '変更時は一度Silverプランの退会手続きを行い、',
    txt_md_change_silver_to_gold_2: 'Goldプランへの新規入会手続きを行います。',
    txt_md_change_gold_to_silver_1: '会員更新のタイミングでGoldプランの退会手続きを行い、',
    txt_md_change_gold_to_silver_2: 'Silverプランへの新規入会手続きを行います。',
    txt_md_change_to_bronze_1: '会員更新のタイミングでGoldプランまたはSilverプランの退会手続きを行い、',
    txt_md_change_to_bronze_2: 'Bronzeプランへの新規入会手続きを行います。',

    // ___________"Confirm total money needed to pay" modal___________
    title_md_recheck_bill: "お支払いの確認",
    msg_total_bill: "お支払い金額 ：",
    msg_confirm_recheck: "よろしいでしょうか？",
    btn_ok: "OK",

    // ___________________"Join rank failed" modal___________________
    title_md_err: 'エラー',

    // >> Register failed
    msg_registered_failure: 'ランク登録でエラーが発生しました。',

    // >> Change failed
    msg_changed_failure: 'ランク変更でエラーが発生しました。',

    // >> Renewal failed
    msg_renewaled_failure: 'ランク更新でエラーが発生しました。',

    // ____________________"No Internet" modal____________________
    noti_network_err: '接続エラー',
    noti_network_err_des: 'インターネット接続がありません。',

    // ____________________"Ms update rank "____________________
    msp1_update_rank:'お客様のアカウントは',
    msp2_update_rank:'ランクへ自動更新予定です。',
    msp3_update_rank:'ランクへ自動更新予定です。更新のための年会費支払いは不要となります。',

    txt_warning_money_point1:'money残高が不足しています。',
    txt_warning_money_point2:'専用のチャージ機でチャージしてください。',

    // Config text for specific company:
    [`${COMPANY_CODES.KASUMI}`]: {
        // "Check credit card (phone num screen)" modal
        msg_credit_card_status_3: '※「Bronze」ランクへご登録いただく場合、お支払いは発生しません。',
        btn_PayPay_or_point: 'PayPayもしくはポイントで支払う',

        // "Home" screen
        msg_regis_rank_row_2a: '登録したいランクをタッチ',
        msg_regis_rank_row_2b: 'してください。',

        // Common
        txt_rank_member: '[BLANDE Prime　会員規約',

        // ____________________"Out rank" modal____________________
        // >> Step 1
        msg_warning_out_rank_1: '　第9条］に基づき、',
        msg_warning_out_rank_3c: '前回会員登録の入会日より1年間は同じランクでの再入会ができません。',

        // btn register
        title_btn_register_rank: '会員ランク登録',
    },
    [`${COMPANY_CODES.MAX_VALUE}`]: {
        // "Check credit card (phone num screen)" modal
        btn_PayPay_or_point: 'ポイントで支払う',

        // "Home" screen
        msg_regis_rank_row_2a: '「会員ランク登録」ボタンを',
        msg_regis_rank_row_2b: 'タッチしてください。',

        // Common
        txt_rank_member: '[マックスバリュプライム　会員規約',

        // ____________________"Out rank" modal____________________
        // >> Step 1
        msg_warning_out_rank_1: '　第8条］に基づき、',
        msg_warning_out_rank_3c: '前回会員登録の入会日より1年間は再入会ができません。',

        // btn register
        title_btn_register_rank: '会員ランク登録',
    },
    [`${COMPANY_CODES.MARUETSU}`]: {
        // "Check credit card (phone num screen)" modal
        btn_PayPay_or_point: 'PayPayもしくはポイントで支払う',

        // "Home" screen
        msg_regis_rank_row_2a: '「会員登録」ボタンを',
        msg_regis_rank_row_2b: 'タッチしてください。',

        // Common
        txt_rank_member: '[マルエツプライム 会員規約',

        // ____________________"Out rank" modal____________________
        // >> Step 1
        msg_warning_out_rank_1: '　第8条］に基づき、',
        msg_warning_out_rank_3c: '前回会員登録の入会日より1年間は再入会ができません。',

        // btn register
        title_btn_register_rank: '会員登録',
    },
}

export default stringJp;

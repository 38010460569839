// import { Modal } from 'antd'
import stringJp from '../../../constants/string'
// import './ModalNoInternet.scss'

export const openModalNoInternet = () => {
    alert(stringJp.noti_network_err_des)

    // Modal.warning({
    //     title: stringJp.noti_network_err,
    //     content: stringJp.noti_network_err_des,
    //     centered: true,
    //     destroyOnClose: true,
    //     onCancel: () => Modal.destroyAll(),
    //     className: 'md-no-internet',
    //     width: 350
    // })
}
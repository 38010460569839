import React, { useState } from 'react'
// import { Document, Page, pdfjs } from 'react-pdf'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { Spin } from 'antd'
import { getCompanyCodeParam } from '../../contexts/functions'
import { company } from '../../constants/configCompany'

// pdfjs.GlobalWorkerOptions.workerSrc =`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PDFViewer({ onSuccess }) {
    const [numPages, setNumPages] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setIsLoading(false)
        onSuccess()
    }

    // https://cors-anywhere.herokuapp.com/
    // CORS Anywhere is too famous so it easily to get error "429 (Too many request)"

    const pdfUrl = company[`${getCompanyCodeParam()}`]?.PDF_URL

    return (
        <Spin spinning={isLoading}>
            <Document
                file={pdfUrl}  // temp, it's a CORS proxy to bypass CORS
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setIsLoading(false)}
                loading=''
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={document.getElementById('pdf-container')?.offsetWidth}
                        />
                    ),
                )}
            </Document>
        </Spin>
    )
}

export default PDFViewer

import ReactGA from "react-ga";
//import ReactGA4 from "react-ga4";
import { ACCOUNT_ID } from "../../constants";
import { LcStorage } from "../storage";
const InitializeGoogleAnalytics = () => {
  let tracking_GA = `${process.env.REACT_APP_GA_TRACKING_CODE}`;
  //let  tracking_GA4 = `${process.env.REACT_APP_GA4_TRACKING_CODE}`

  // Initialize UA
  ReactGA.initialize(tracking_GA);

  // Initialize GA4 - Add your measurement ID
  //ReactGA4.initialize(tracking_GA4);
};

const TrackGoogleAnalyticsEvent = (dataRp) => {
  let account_id = LcStorage.getLocalJson(ACCOUNT_ID);
  const convertTime = () => {
    let time = new Date();
    let day = time.getDate();
    let month = time.getMonth() + 1; // month begin 0 so need plus + 1
    let year = time.getFullYear() % 100; // get the last 2 digits
    let hour = time.getHours();
    let minute = time.getMinutes();

    // Make date with format "dd/mm/yy hh:mm"
    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  const labelContent = (code, messageSttApi) => {
    return `${account_id},${code},${convertTime()},${messageSttApi}`;
  };

  const codeMatrixApi = [
    {
      code: 1,
      btn_name: "SMS認証_画面アクセス",
      api_name: "SearchAccountCreditCard",
    },
    {
      code: 2,
      btn_name: "SMSを送信_ボタン",
      api_name: "SmsIssue",
    },
    {
      code: 3,
      btn_name: "SMS認証_ボタン",
      api_name: "SmsAuth",
    },
    {
      code: 4,
      btn_name: "会員情報_画面アクセス",
      api_name: "GetAccountRankHistory",
    },
    {
      code: 5,
      btn_name: "会員情報_画面アクセス",
      api_name: "SearchAccountRankGroup",
    },
    {
      code: 6,
      btn_name: "退会_ボタン",
      api_name: "LeaveAccountRank",
    },
    {
      code: 7,
      btn_name: "ブロンズ入会_確認ボタン",
      api_name: "RegistAccountRank",
    },
    {
      code: 8,
      btn_name: "ポイント支払_ボタン",
      api_name: "SettlementAndRegistAccountRank",
    },
    {
      code: 9,
      btn_name: "会員規約_確認ボタン",
      api_name: "GetAccountRankPaymentMethod",
    },
    {
      code: 10,
      btn_name: "PayPay_確認ボタン",
      api_name: "GetPayPayRequestAccountRank",
    },
    {
      code: 11,
      btn_name: "PayPayランク更新完了_画面アクセス",
      api_name: "CheckPaymentStatus",
    },
    {
      code: 12,
      btn_name: "null",
      api_name: "other",
    },
  ];

  const action = () => {
    let stringCheck = dataRp?.config?.url?.slice(5);
    let item = codeMatrixApi.find((item) => {
      return item.api_name === stringCheck;
    });
    if (item) {
      if (dataRp.status === 200) {
        return labelContent(item.code, "OK");
      } else {
        return labelContent(item.code, dataRp.data.message);
      }
    }

    if (dataRp === "errorMsg") {
      return labelContent("test", "errorMsg");
    }
    if (dataRp === "!listRanks") {
      return labelContent("test", "!listRanks");
    }
    if (dataRp === "len<1") {
      return labelContent("test", "len<1");
    }
    if (dataRp === "passBtnTest") {
      return labelContent("test", "passBtnTest");
    }
    if (dataRp === "endNowDayUndefine") {
      return labelContent("logbug", "endNowDayUndefine");
    }
  };

  // Send UA Event
  ReactGA.event({
    category: "API呼び出し",
    action: action(),
    label: "",
  });
  // Send GA4 Event
  // ReactGA4.event({
  //     category: 'API呼び出し',
  //     action: action(),
  //     label: '',
  // });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };

import React, { useState } from 'react';
import { Modal } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons'

import stringJp from '../../../constants/string'
import { kasumiCompany } from '../../../constants/configCompany'
import "./ModalRenewalAuto.scss";

const ModalRenewalAuto = (props) => {
    const { visible, setVisible, content} = props
    const { RANK_ID } = kasumiCompany
    const renderContent = () => {
       
        return <>
            {content}
        </>
    }

    const handleExit = () => {
        setVisible(false)
    }

    return (
        <Modal
            title=""
            className="md-warning-up-down-rank"
            centered
            open={visible}
            onCancel={handleExit}
            // onCancel={!isCheck ? handleExit : null} when maskClosable={true}
            footer={null}
            closable={false}
            width={345}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-confirm-wrapper">
                    <div className="icon-status-confirm">
                        <ExclamationOutlined className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">{'自動更新'}</p>
                    {renderContent()}
                </div>

                <div className="btn-group-container">
                    <div
                        className="btn-confirm"
                        onClick={handleExit}
                    >
                        {stringJp.btn_agree}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalRenewalAuto; 
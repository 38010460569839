import React from 'react'
import stringJp from '../../../constants/string'
import "./indexConfirmModal.scss"

function AccountInfo({ userInfo }) {
    return (
        <>
            <div className="account-info">
                <b>{userInfo?.accountName || ''}</b>
                {' '}{stringJp.txt_sama}
            </div>
            <p className="account-info">
                {stringJp.text_userId}{' '}
                {userInfo?.accountId || ''}
            </p>
        </>
    )
}

export default AccountInfo
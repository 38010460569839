import React, { useState, useEffect } from 'react'
import {
    useNavigate, useLocation,
    useSearchParams, createSearchParams
} from "react-router-dom"
import { LcStorage } from '../../../utils/storage'
import {
    ACTION,
    IS_SUCCESS,
    PAYPAY_ORDER_ID,
} from '../../../constants'
import { ERROR_SCREEN, POLICY_SCREEN } from '../../../constants/path'
import { checkPayPayPaymentStatus } from '../../../contexts/actions/memberInfo'
import MainLayout from '../../../layout/MainLayout'
import './PendingScreen.scss'

function PendingScreen() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const currentLocation = useLocation()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 2) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            setTimeout(() => {
                // localStorage.removeItem('openPayPay');
                window.location.reload();
            }, 1000);
        } else {
            sessionStorage.removeItem('reloadCount');
        }

        // setInterval(() => {
        //     localStorage.removeItem('openPayPay');
        //     window.location.reload();
        // }, 2500);

    }, [])

    useEffect(() => {
        const openPayPay = localStorage.getItem('openPayPay');
        if (openPayPay < 1 && +LcStorage.getLocalJson(IS_SUCCESS) !== 1) {
            localStorage.setItem('openPayPay', String(openPayPay + 1));
            if (currentLocation.state && currentLocation?.state?.paypayURL && !searchParams?.get('paypay')) {
                window.open(currentLocation?.state?.paypayURL, '_self', 'noopener,noreferrer')
            }
        }else{
            if(openPayPay === '1' && +LcStorage.getLocalJson(IS_SUCCESS) !== 1){
                navigate({
                    pathname: `/${POLICY_SCREEN}`,
                    search: createSearchParams({
                        action: searchParams.get(ACTION) || ''
                    }).toString()
                }, { replace: true })
            }
        }

        return()=>{
            localStorage.removeItem('openPayPay');
        }
    }, [])

    useEffect(() => {
        if (+LcStorage.getLocalJson(IS_SUCCESS) === 1) { return navigate(`/${ERROR_SCREEN}`) }
        if (searchParams?.get('paypay') === 'success') {
            let orderId = LcStorage.getLocalJson(PAYPAY_ORDER_ID)
            setIsLoading(true)
            checkPayPayPaymentStatus(handleDataResponse, orderId)
        }
        return () => {
            setIsLoading(false)
        }
    }, [searchParams, currentLocation])   // currentLocation

    const handleDataResponse = res => {
        setIsLoading(false)
        if (res?.status === 200 && res?.data?.success && res?.data?.status === 'paid') {
            LcStorage.removeLocal(PAYPAY_ORDER_ID)
            LcStorage.setLocalJson(IS_SUCCESS, 1)
            navigate('/success')  // , { replace: true }
        }
    }

    return (
        <MainLayout
            color={'#ffffff'}
            isLoading={isLoading}
            className="pending-screen"
        />
    )
}

export default PendingScreen

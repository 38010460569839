import React from 'react';
import { Modal, Button } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

import stringJp from '../../../constants/string'
import "./ModalRecheckBill.scss";

const ModalRecheckBill = (props) => {
    const { visible, setVisible, totalFee, setClickedButton, handlePayment, loadingBtn } = props

    const closeModal = () => {
        setClickedButton(null)
        setVisible(false)
    }

    const handleConfirm = () => {
        handlePayment()
        closeModal()
    }

    return (
        <Modal
            title=""
            className="md-recheck-bill"
            centered
            open={visible}
            onCancel={closeModal}
            footer={null}
            closable={false}
            width={360}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-confirm-wrapper">
                    <div className="icon-status-confirm">
                        <ExclamationOutlined className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">{stringJp.title_md_recheck_bill}</p>
                    <p className="md-message-warning">
                        {stringJp.msg_total_bill}{`${stringJp.txt_symbol_yen}${totalFee}`}
                        <br />
                        <span>{stringJp.msg_confirm_recheck}</span>
                    </p>
                </div>

                <div className="btn-group-container">
                    <Button 
                        style={{ height: 45}}
                        className="btn-cancel" 
                        onClick={closeModal}>
                        {stringJp.text_button_cancel}
                    </Button>
                    <Button 
                        style={{ height: 45}}
                        loading={loadingBtn}
                        className="btn-ok" 
                        onClick={handleConfirm}>
                        {stringJp.btn_ok}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalRecheckBill

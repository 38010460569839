import { useState } from "react"
import cookies from "js-cookie"
import { USER_KEY } from "../constants"
import { getAccountIdLocal, getCompanyCodeLocal } from "./functions"

export function useAuth() {
    const [userData, setUserData] = useState(null)

    const isAuth = (cookies.get(USER_KEY) ? true : false)
        && !!getAccountIdLocal()
        && !!getCompanyCodeLocal()

    const setAuth = (codeSMS = userData?.codeSMS) => {
        cookies.set(USER_KEY, `${userData?.phoneNum}${codeSMS}`)
    }

    const resetAuth = () => {
        setUserData(null)
        cookies.remove(USER_KEY)
    }

    return { isAuth, setAuth, resetAuth, setUserData }
}
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useAuth } from '../contexts'
import { ACCOUNT_ID, COMPANY_CODE, SYSTEM_ID, STORE_CODE, IN_STORE_CODE } from '../constants'

import {
    getAccountIdLocal, 
    getCompanyCodeLocal, 
    getSystemIdLocal,
    getStoreCodeLocal,
    getInStoreCodeLocal
} from '../contexts/functions'

function PrivateRoutes() {
    const { isAuth } = useAuth()
    const { pathname } = useLocation()
    const [queryParams, setQueryParams] = useSearchParams()
    const [isParamChanged, setIsParamChanged] = useState(false)
    console.log("queryParams", queryParams);
    console.log("pathname", pathname);

    useEffect(() => {
        let isMounted = true

        if (!isMounted) return

        const account_id = getAccountIdLocal()
        const company_code = getCompanyCodeLocal()
        const system_id = getSystemIdLocal()

        const store_code = getStoreCodeLocal()
        const instore_code = getInStoreCodeLocal()

        let currentParams = {}

        queryParams?.forEach((value, key) => {
            currentParams[key] = value
        })

        if (
            !queryParams.get(ACCOUNT_ID) 
            && !queryParams.get(COMPANY_CODE)
            && !queryParams.get(SYSTEM_ID)
            && !queryParams.get(STORE_CODE)
            && !queryParams.get(IN_STORE_CODE)
        ) {
            if (account_id && company_code && system_id && !store_code  && !instore_code) {
                setQueryParams(
                    { ...currentParams, 
                        company_code, 
                        account_id, 
                        system_id 
                    },
                    { replace: true }
                )
            }else if(account_id && company_code && system_id && store_code  && instore_code) {
                setQueryParams(
                    { ...currentParams, 
                        company_code, 
                        account_id, 
                        store_code, 
                        instore_code,
                        system_id 
                    },
                    { replace: true }
                )
            }else{
                setIsParamChanged(true)
            }
        }
        else if (queryParams.get(ACCOUNT_ID) !== account_id
            || queryParams.get(COMPANY_CODE) !== company_code
            // || queryParams.get(STORE_CODE) !== store_code
            // || queryParams.get(IN_STORE_CODE) !== instore_code
            || queryParams.get(SYSTEM_ID) !== system_id) {
            setIsParamChanged(true)
        }

        return () => { isMounted = false }
    }, [pathname])

    return isAuth && !isParamChanged ? <Outlet /> : <Navigate to="/" />
}

export default PrivateRoutes

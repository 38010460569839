import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    createSearchParams, useLocation, useNavigate, useSearchParams
} from "react-router-dom"
import { Radio, Space } from 'antd'

import { LcStorage } from '../../../utils/storage'
import { ERROR_SCREEN, POLICY_SCREEN } from '../../../constants/path'
import stringJp from '../../../constants/string'
import { kasumiCompany } from '../../../constants/configCompany'
import { ACTION, IS_SUCCESS, SELECTION_STATE } from '../../../constants'

import {
    useLastLocation
} from '../../../contexts/location/LastLocationContext'
import { useRank } from '../../../contexts/ranks/RankContext'
import {
    checkInternetWorking,
    findRank, getAdmissionFee, getListAvailableRanks,
    getRankImgSrc, getScreenOrModalTitle,
    getMyRank, getPastRanks, getSelectionState, formatDate
} from '../../../contexts/functions'

import MainLayout from '../../../layout/MainLayout'
import {
    StyledButton, openModalNoInternet, ModalUpDownRank
} from '../../../components'
import './indexSelectionScreen.scss'
import ModalRenewalAuto from '../../../components/Modal/ModalRenewalAuto'
import moment from 'moment'

function RankSelection({ listRanks }) {
    if (!listRanks || !listRanks?.length) return <></>

    return (
        listRanks?.map(rank => {
            const rankId = rank?.account_rank_group_id

            return (
                <Radio value={rankId} key={rankId}>
                    <div className='option-wrapper'>
                        <div className="img-card-rank">
                            <img src={getRankImgSrc(rankId)}
                                alt={`rank-${rankId}`}
                                width="100%" height="100%"
                            />
                        </div>
                        <p className="txt-admission-fee">
                            {stringJp.txt_membership_fee}
                            {' ：'}
                            {getAdmissionFee(rank?.admission_fee)}
                            {rank?.admission_fee ? stringJp.txt_tax_included : ''}
                        </p>
                    </div>
                </Radio>
            )
        })
    )
}

const SelectionScreen = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const currentLocation = useLocation()
    const { updateLastLocation } = useLastLocation()

    const {
        userInfo,
        selectedRank, setSelectedRank,
        listRanks, setListRanks,
        fetchListRanks,
        isLoading, errorMsg,
        openMdRejoinPrevRank, setOpenMdRejoinPrevRank
    } = useRank()
    const selectedRankId = selectedRank?.account_rank_group_id
    const currentRankId = getMyRank()?.account_rank_group_id
    const [openMdUpDownRank, setOpenMdUpDownRank] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [ visibleRenewalAuto, setVisibleRenewalAuto ] = React.useState(false);
    const [ mesRenewalAuto, setMesRenewalAuto ] = React.useState('');
    const { RANK_ID } = kasumiCompany

    useEffect(() => {
        checkInternetWorking().then(value => {
            setIsOnline(value)
            if (!value) openModalNoInternet()
        })
    }, [])
    // Navigate to screen Error
    useEffect(() => {
        if (+LcStorage.getLocalJson(IS_SUCCESS) === 1) {
            updateLastLocation(currentLocation.pathname)
            navigate(`/${ERROR_SCREEN}`)    // , { replace: true }
        }

        return () => setOpenMdRejoinPrevRank(false)
    }, [])

    useEffect(() => {
        if (!currentLocation?.search) return

        if (!listRanks || listRanks?.length <= 0)
            fetchListRanks(currentRankId)
        else
            setListRanks(getListAvailableRanks(currentRankId, listRanks))
    }, [currentLocation?.search])

    useLayoutEffect(() => {
        // Check whether should show popup or not
        const listPrevRanks = getPastRanks() || []

        if (selectedRankId && currentRankId
            && currentRankId !== kasumiCompany.RANK_ID.BRONZE) {
            setOpenMdUpDownRank(true)
        }

        // Only display 1 popup at a time, prioritize the MdRejoinPrevRank

        if (selectedRankId && findRank(listPrevRanks, selectedRankId)) {
            let dateCreate = findRank(listPrevRanks, selectedRankId); 
            let endNowDay = new Date(dateCreate?.application_start_dt);
            endNowDay?.setFullYear(endNowDay?.getFullYear() + 1, endNowDay?.getMonth() + 1, 1 ); 

            if (endNowDay) {
                if (new Date() < endNowDay) {
                    setOpenMdRejoinPrevRank(true);
                }
            }
            setOpenMdUpDownRank(false);
        }
    }, [selectedRank])

    const handleChangeRank = e => {
        const option = e?.target?.value
        const rank = findRank(listRanks, option)
        setSelectedRank(rank)

        // Set selected rank into localStorage for keeping state
        LcStorage.setLocalJson(SELECTION_STATE, {
            ...getSelectionState(),
            rank,
            action: searchParams.get(ACTION)
        })
    }

    const handleClickNext = () => {

        const expRenewal = formatDate( moment(userInfo?.expDate)?.add(1, 'day'), 'LLL')?.replace(/(00:00)/g, '');

        function handleNavigate(params) {
            updateLastLocation(currentLocation.pathname)
            navigate({
                pathname: `/${POLICY_SCREEN}`,
                search: createSearchParams({
                    action: searchParams.get(ACTION) || ''
                }).toString()
            })
        }

        if(currentRankId === RANK_ID.GOLD ){
            if(selectedRank.account_rank_group_id === RANK_ID.SILVER){
                if(userInfo.differenceGold > 0){
                    //TH2: Gold -> Silver && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ) {
                    // TH2: Gold -> Silver &&   119999< total < 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }

            if(selectedRank.account_rank_group_id === RANK_ID.BRONZE){

                if(userInfo.differenceGold > 0){
                    //TH3: Gold -> Bronze && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ) {
                    // TH3: Gold -> Bronze &&   119999< total < 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }

        }else if(currentRankId === RANK_ID.SILVER){

            if(selectedRank.account_rank_group_id === RANK_ID.GOLD){
                if (userInfo.differenceGold > 0 ) {
                    //TH4: Silver -> Gold && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ){
                     //TH4: Silver -> Gold && 119999< total < 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }

            if(selectedRank.account_rank_group_id === RANK_ID.BRONZE){
                if (userInfo.differenceGold > 0 ) {
                    //TH6: Silver -> Bronze && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ){
                     //TH6: Silver -> Bronze && 119999< total < 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }

        }else if(currentRankId === RANK_ID.BRONZE){
            if(selectedRank.account_rank_group_id === RANK_ID.GOLD){
                if (userInfo.differenceGold > 0 ) {
                    // TH7: Bronze -> Gold  && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ){
                   // TH7: Bronze -> Gold && 119999< total < 120000
                   setVisibleRenewalAuto(true);
                   setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }

            if(selectedRank.account_rank_group_id === RANK_ID.SILVER){

                if (userInfo.differenceGold > 0 ) {
                    // TH8: Bronze -> Silver  && total > 120000
                    setVisibleRenewalAuto(true);
                    setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からGold'+ stringJp.msp2_update_rank} `)
                }else if(userInfo.differenceSilver > 0 && userInfo.differenceGold < 0 ){
                   // TH8: Bronze -> Silver && 119999< total < 120000
                   setVisibleRenewalAuto(true);
                   setMesRenewalAuto(`${stringJp.msp1_update_rank + expRenewal +'からSilver'+ stringJp.msp2_update_rank} `)
                }else{
                    handleNavigate();
                }
            }
        }else{
            handleNavigate();
        }
    }

    return (
        <MainLayout className="selection-screen"
            isLoading={isLoading}
            screenTitle={getScreenOrModalTitle(searchParams.get(ACTION))}
            color={'#ffffff'}
        >
            <div className="body-wrapper">
                <p className="message">{stringJp.txt_selection_message}</p>

                {errorMsg && (
                    <div className='txt-err'>
                        {errorMsg}
                    </div>
                )}

                {(isOnline && !errorMsg) && (
                    <>
                        <div className='all-selections-wrapper'>
                            <Radio.Group
                                value={selectedRank?.account_rank_group_id}
                                onChange={handleChangeRank}
                            >
                                <Space direction="vertical">
                                    <RankSelection listRanks={listRanks} />
                                </Space>
                            </Radio.Group>
                        </div>

                        <div className="btn-confirm-wrapper">
                            <StyledButton
                                label={stringJp.title_btn_confirm}
                                onClick={selectedRank ? handleClickNext : null}
                                style={{ opacity: !selectedRank ? 0.6 : 1 }}
                            />
                        </div>
                    </>
                )}
            </div>

            {(openMdUpDownRank && !openMdRejoinPrevRank && !userInfo.differenceGold && !userInfo.differenceSilver) && (
                <ModalUpDownRank
                    visible={openMdUpDownRank}
                    setVisible={setOpenMdUpDownRank}
                    selectedRankId={selectedRankId}
                    currentRankId={currentRankId}
                />
            )}

            <ModalRenewalAuto
                content={mesRenewalAuto}
                visible={visibleRenewalAuto}
                setVisible={setVisibleRenewalAuto}
            />
        </MainLayout>
    )
}

export default SelectionScreen

import _ from 'lodash'
import isOnline from 'is-online'
import momenttz from 'moment-timezone'
import moment from 'moment'
import 'moment/locale/ja'

import { LcStorage } from '../utils/storage'
import { PAYMENT_SCREEN } from "../constants/path"
import stringJp from "../constants/string"
import {
    ACCOUNT_ID, COMPANY_CODE, SYSTEM_ID,
    ACTION_TYPE, HAD_CREDIT_CARD,
    MY_RANK, PAST_RANKS,
    SELECTION_STATE, STORE_CODE, IN_STORE_CODE
} from "../constants"
import {
    COMPANY_CODES, kasumiCompany, maxValueCompany, maruetsuCompany
} from '../constants/configCompany'
import {
    ImgMaxValuePrime, ImgMaxValueNoRank,
    ImgKasumiNoRank, ImgKasumiRankBronze,
    ImgKasumiRankGold, ImgKasumiRankSilver,
    ImgMaruetsuPrime, ImgMaruetsuNoRank, MaruestsuSuccess
} from "../assets/img"


export const getAccountIdParam = () => (new URL(window.location.href))?.searchParams.get(ACCOUNT_ID)
export const getCompanyCodeParam = () => (new URL(window.location.href))?.searchParams.get(COMPANY_CODE)

export const getStoreCodeParam = () => (new URL(window.location.href))?.searchParams.get(STORE_CODE)
export const getInStoreCodeParam = () => (new URL(window.location.href))?.searchParams.get(IN_STORE_CODE)

export const getStoreCodeLocal = () => LcStorage.getLocalJson(STORE_CODE)
export const getInStoreCodeLocal = () => LcStorage.getLocalJson(IN_STORE_CODE)

export const getSystemIdParam = () => (new URL(window.location.href))?.searchParams.get(SYSTEM_ID)
export const getAccountIdLocal = () => LcStorage.getLocalJson(ACCOUNT_ID)
export const getCompanyCodeLocal = () => LcStorage.getLocalJson(COMPANY_CODE)

export const getSystemIdLocal = () => LcStorage.getLocalJson(SYSTEM_ID)

export const checkHadCreditCard = () => LcStorage.getLocalJson(HAD_CREDIT_CARD)

export const getMyRank = () => LcStorage.getLocalJson(MY_RANK)

export const getPastRanks = () => LcStorage.getLocalJson(PAST_RANKS)

export const getSelectionState = () => LcStorage.getLocalJson(SELECTION_STATE)
export const checkHasPayPay = getSelectionState()?.method?.payPay

const { RANK, RANK_ID, RANK_FULL_NAME_LOWER, RANK_FULL_NAME_UPPER } = kasumiCompany

/**
 * Find a rank from a list of ranks
 * @param {Array} listRanks list where want to find a rank
 * @param {String} rankId ID of finding rank
 * @returns found rank
 */
const findRank = (listRanks, rankId) => {
    return listRanks?.find(rank => rank?.account_rank_group_id == rankId)
}

/**
 * Sort list of ranks in order asc by rank's ID
 * @param {Array} listRanks original list of ranks
 * @returns sorted list of rank
 */
const sortListRank = listRanks => {
    return listRanks.sort((rank1, rank2) =>
        +rank1?.account_rank_group_id - +rank2?.account_rank_group_id
    )
}

/**
 * Get list available ranks
 * @param {String} currentRankId ID of the current rank
 * @param {Array} listRanksAPI list of ranks which is fetched from API
 * @returns list of ranks which does not include the current rank
 */
const getListAvailableRanks = (currentRankId, listRanksAPI) => {
    const listRanks = [...listRanksAPI]

    if (currentRankId)
        _.remove(listRanks, rank => rank?.account_rank_group_id === currentRankId)

    return sortListRank(listRanks)
}

/**
 * Get custom rank name based on the standing screen & is upper case or not
 * @param {String} rankId original rank's ID
 * @param {String} screenName Standing screen's name
 * @param {Boolean} isUpper Is rank name in upper case
 * @returns Custom rank name
 */
const getRankName = (rankId, screenName, isUpper = false) => {

    switch (getCompanyCodeParam()) {
        case COMPANY_CODES.KASUMI:  // 100
            let rankName = ''
            switch (rankId) {
                case RANK_ID.GOLD:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.GOLD
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.GOLD
                            : RANK_FULL_NAME_LOWER.GOLD
                    break

                case RANK_ID.SILVER:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.SILVER
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.SILVER
                            : RANK_FULL_NAME_LOWER.SILVER
                    break

                case RANK_ID.BRONZE:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.BRONZE
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.BRONZE
                            : RANK_FULL_NAME_LOWER.BRONZE
                    break

                default:
                    break
            }
            return rankName || ''

        case COMPANY_CODES.MAX_VALUE:   // 300

        case COMPANY_CODES.MARUETSU:   // 200
        
        default:
            return getMyRank()?.account_rank_group_name || ''
    }

}

const getRankNameSuccess = (rankId, screenName, isUpper = false) => {
    switch (getCompanyCodeLocal()) {
        case COMPANY_CODES.KASUMI:  // 100
            let rankName = ''
            switch (rankId) {
                case RANK_ID.GOLD:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.GOLD
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.GOLD
                            : RANK_FULL_NAME_LOWER.GOLD
                    break

                case RANK_ID.SILVER:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.SILVER
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.SILVER
                            : RANK_FULL_NAME_LOWER.SILVER
                    break

                case RANK_ID.BRONZE:
                    rankName = screenName === `/${PAYMENT_SCREEN}`
                        ? RANK.BRONZE
                        : isUpper
                            ? RANK_FULL_NAME_UPPER.BRONZE
                            : RANK_FULL_NAME_LOWER.BRONZE
                    break

                default:
                    break
            }
            return rankName || ''

        case COMPANY_CODES.MAX_VALUE:   // 300

        case COMPANY_CODES.MARUETSU:   // 200

        default:
            return getMyRank()?.account_rank_group_name || ''
    }

}

/**
 * Get rank's thumbnail by rank name
 * @param {String} rankId rank's ID
 * @returns src of rank's thumbnail
 */
const getRankImgSrc = (rankId = '') => {
    switch (getCompanyCodeParam()) {
        case COMPANY_CODES.KASUMI:  // 100
            let rankImgSrc = ''
            switch (rankId) {
                case RANK_ID.GOLD:
                    rankImgSrc = ImgKasumiRankGold
                    break

                case RANK_ID.SILVER:
                    rankImgSrc = ImgKasumiRankSilver
                    break

                case RANK_ID.BRONZE:
                    rankImgSrc = ImgKasumiRankBronze
                    break

                default:
                    rankImgSrc = ImgKasumiNoRank
                    break
            }
            return rankImgSrc

        case COMPANY_CODES.MAX_VALUE:   // 300
            return rankId === maxValueCompany.RANK_ID.MAX_VALUE
                ? ImgMaxValuePrime
                : ImgMaxValueNoRank

        case COMPANY_CODES.MARUETSU:   // 200
            return rankId === maruetsuCompany.RANK_ID.MARUETSU
                ? ImgMaruetsuPrime
                : ImgMaruetsuNoRank
        default:
            return ImgKasumiNoRank  // temp
    }
}

/**
 * Get rank's thumbnail by rank name
 * @param {String} rankId rank's ID
 * @returns src of rank's thumbnail
 */

const getRankImgSrcSuccess = (rankId = '') => {

    switch (getCompanyCodeLocal()) {
        case COMPANY_CODES.KASUMI:  // 100
            let rankImgSrc = ImgKasumiRankGold
            switch (rankId) {
                case RANK_ID.GOLD:
                    rankImgSrc = ImgKasumiRankGold
                    break

                case RANK_ID.SILVER:
                    rankImgSrc = ImgKasumiRankSilver
                    break

                case RANK_ID.BRONZE:
                    rankImgSrc = ImgKasumiRankBronze
                    break

                default:
                    rankImgSrc = ImgKasumiNoRank
                    break
            }
            return rankImgSrc

        case COMPANY_CODES.MAX_VALUE:   // 300
            return rankId === maxValueCompany.RANK_ID.MAX_VALUE
                ? ImgMaxValuePrime
                : ImgMaxValueNoRank

        case COMPANY_CODES.MARUETSU:   // 200
            return rankId === maruetsuCompany.RANK_ID.MARUETSU
                ? MaruestsuSuccess
                : ImgMaruetsuNoRank

        default:
            return ImgKasumiNoRank  // temp
    }
}

/**
 * Get rank fee in currency format
 * @param {Number} rankFee original rank fee
 * @returns Rank fee & currency (JPY)
 */
const getAdmissionFee = rankFee => {
    return rankFee === 0
        ? stringJp.txt_free
        : `${rankFee}${stringJp.txt_yen}`
}

const getOutRankIframeUrl = rankId => {
    switch (getCompanyCodeParam()) {
        case COMPANY_CODES.KASUMI:  // 100
            let url = ''
            switch (rankId) {
                case RANK_ID.GOLD:
                    url = kasumiCompany.OUT_RANK_GOLD_URL
                    break
                case RANK_ID.SILVER:
                    url = kasumiCompany.OUT_RANK_SILVER_URL
                    break
                case RANK_ID.BRONZE:
                    url = kasumiCompany.OUT_RANK_BRONZE_URL
                    break
                default:
                    break
            }
            return url

        case COMPANY_CODES.MAX_VALUE:   // 300
            return rankId === maxValueCompany.RANK_ID.MAX_VALUE
                ? maxValueCompany.OUT_RANK_URL : ''

        case COMPANY_CODES.MARUETSU:   // 200
            return rankId === maruetsuCompany.RANK_ID.MARUETSU
                ? maruetsuCompany.OUT_RANK_URL : ''

        default:
            return ''
    }
}

const getCompanyFavicon = companyCode => {
    switch (companyCode) {
        case COMPANY_CODES.KASUMI:  // 100
            return '/blande_favicon.ico'

        case COMPANY_CODES.MARUETSU:   // 200
            return '/maruetsu_favicon.ico'

        case COMPANY_CODES.MAX_VALUE:   // 300
            return '/maxvalue_favicon.ico'

        default:
            return '/sg_favicon.ico'
    }
}

/**
 * Get title of screen or modal based on type of actions on rank and payment status
 * @param {String} actionType Type of actions on rank (Register/Change/Renewal)
 * @param {Boolean} status Payment status (success = true, failure = false)
 * @returns Title of screen or modal
 */
const getScreenOrModalTitle = (actionType, status) => {
    let title = ''

    switch (actionType) {
        case ACTION_TYPE.CHANGE:
            title += stringJp.title_change_rank
            break;

        case ACTION_TYPE.RENEWAL:
            title += stringJp.title_renewal_rank
            break;

        default:    // ACTION_TYPE.REGISTER
            title += stringJp.title_register_rank
            break;
    }

    switch (status) {
        case true:
            title += stringJp.title_screen_payment_success
            break;

        case false:
            title += stringJp.title_md_err
            break;

        default:    // null
            break;
    }

    return title
}

/**
 * Get error messages based on type of actions on rank
 * @param {string} actionType Type of actions on rank (Register/Change/Renewal)
 * @returns 
 */
const getErrorMessages = actionType => {
    switch (actionType) {
        case ACTION_TYPE.CHANGE:
            return stringJp.msg_changed_failure

        case ACTION_TYPE.RENEWAL:
            return stringJp.msg_renewaled_failure

        default:    // ACTION_TYPE.REGISTER
            return stringJp.msg_registered_failure
    }
}

/**
 * @param {string} expDateAPI expiration date fetched from API
 * @returns Difference from input date to now
 */
const gapBetween2Dates = expDateAPI => {
    // ignoreCache = true ("tz" will refresh every time user change timezone in device)
    const userTZ = momenttz.tz.guess(true)

    // Parse current time and expDate time to user timezone.
    // It will be not effected by time in user's local device.
    const expDate = momenttz.tz(expDateAPI, 'YYYY/MM/DD', userTZ)
    const now = momenttz.tz(momenttz(), userTZ)

    return expDate.diff(now, 'days')

    // Old version
    // return moment(expDateAPI, 'YYYY/MM/DD').diff(moment().format('YYYY/MM/DD'), 'days')
}

/**
 * @param {string} dateStr Original date
 * @param {string} format Format that want to parse to
 * @returns Formatted date string
 */
const formatDate = (dateStr, format) => {
    moment.locale('ja', {
        longDateFormat: {
            L: 'MM月DD日', 
            LL: 'YYYY年MMM',
            LLL: 'YYYY年MMMD日'
        }
    })
    return dateStr ? moment(dateStr).format(format) : ''
}

/**
 * Check whether the Internet is working or not
 * @returns {boolean} Connection status
 */
const checkInternetWorking = async () => {
    return await isOnline()
}

const convertSearchParamsIntoObj = searchParams => {
    const params = {}
    searchParams.forEach((value, key) => {
        params[key] = value
    })
    return params
}

export {
    findRank,
    getListAvailableRanks,
    getRankName,
    getRankNameSuccess,
    getRankImgSrc,
    getRankImgSrcSuccess,
    getAdmissionFee,
    getOutRankIframeUrl,
    getErrorMessages,
    getScreenOrModalTitle,
    formatDate, gapBetween2Dates,
    checkInternetWorking,
    convertSearchParamsIntoObj,
    getCompanyFavicon
}
import React, { useEffect } from 'react'
import {
    createSearchParams, useLocation, useNavigate, useSearchParams
} from 'react-router-dom'
import { Modal } from 'antd'
import { CgClose } from 'react-icons/cg'

import { LcStorage } from '../../../utils/storage'
import { useWindowSize } from '../../../contexts'
import { useRank } from '../../../contexts/ranks/RankContext'
import { useLastLocation } from '../../../contexts/location/LastLocationContext'
import {
    checkInternetWorking, getErrorMessages, getScreenOrModalTitle,
    getSelectionState
} from '../../../contexts/functions'
import { HOME_SCREEN, SELECTION_SCREEN } from '../../../constants/path'
import stringJp from '../../../constants/string'
import { kasumiCompany } from '../../../constants/configCompany'
import { PAYPAY_ORDER_ID, ACTION, ACTION_TYPE } from '../../../constants'
import { ButtonCall, ButtonContactEmail } from '../../'
import "./indexModalStatusPayment.scss"

const ModalStatusPayment = (props) => {
    const { visible, setVisible, isFromPayPay } = props

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const currentLocation = useLocation()
    const { updateLastLocation } = useLastLocation()

    const { selectedRank, resetContext } = useRank()

    const [windowWidth] = useWindowSize()

    useEffect(() => {
        checkInternetWorking().then(value => {
            if (!value) setVisible(false)
        })
    }, [])

    const resetData = () => {
        // LcStorage.removeLocal(SELECTION_STATE)
        LcStorage.removeLocal(PAYPAY_ORDER_ID)
    }

    const handleExit = () => {
        setVisible(false)
        updateLastLocation(currentLocation.pathname)

        if (selectedRank?.account_rank_group_id ===
            kasumiCompany.RANK_ID.BRONZE) {
            resetContext()

            if (searchParams.get(ACTION) === ACTION_TYPE.RENEWAL)
                navigate(`/${HOME_SCREEN}`)
            else
                navigate({
                    pathname: `/${SELECTION_SCREEN}`,
                    search: createSearchParams({
                        action: searchParams.get(ACTION)
                    }).toString()
                })
        }

        if (!isFromPayPay) return

        resetData()
    }

    // const handleRedirect = () => {
    //     setVisible(false)
    //     resetContext()
    //     resetData()
    // }

    const selectionState = getSelectionState()
    const modalTitle = getScreenOrModalTitle(selectionState?.action, false)
    const errorMsg = getErrorMessages(selectionState?.action, false)

    return (
        <Modal
            title=""
            className="md-payment-status"
            centered
            open={visible}
            onCancel={handleExit}
            footer={null}
            closable={false}
            maskClosable={false}
            width={430}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-wrapper">
                    <div className="icon-status-payment">
                        <CgClose className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">
                        {modalTitle}
                    </p>
                    <p className="md-message">
                        {errorMsg}
                        <br />
                        {stringJp.msg_failure_ask_call_center}
                        {windowWidth < 768 && <br />}
                        {stringJp.msg_kudasai}
                    </p>
                </div>

                <div className="btn-close-wrapper">
                    <ButtonCall />
                    <ButtonContactEmail handleRedirect={() => setVisible(false)} />

                    <div className="btn-close" onClick={handleExit}>
                        <p>{stringJp.title_btn_exit}</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalStatusPayment; 
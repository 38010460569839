import React, { useState } from 'react';
import { Modal, Checkbox } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons'

import stringJp from '../../../constants/string'
import { kasumiCompany } from '../../../constants/configCompany'
import { useWindowSize } from '../../../contexts'
import { getCompanyCodeParam } from '../../../contexts/functions';
import "./ModalUpDownRank.scss";

const ModalUpDownRank = (props) => {
    const { visible, setVisible, selectedRankId, currentRankId } = props
    const { RANK_ID } = kasumiCompany

    const [windowWidth] = useWindowSize()
    const [isCheck, setIsCheck] = useState(false)

    const renderContent = () => {
        const breakLine = windowWidth < 768 && <br />

        if (currentRankId === RANK_ID.SILVER
            && selectedRankId === RANK_ID.GOLD
        )
            return <>
                {stringJp.txt_md_change_silver_to_gold_1}
                {breakLine}
                {stringJp.txt_md_change_silver_to_gold_2}
            </>
        else if (currentRankId === RANK_ID.GOLD
            && selectedRankId === RANK_ID.SILVER
        )
            return <>
                {stringJp.txt_md_change_gold_to_silver_1}
                {breakLine}
                {stringJp.txt_md_change_gold_to_silver_2}
            </>
        else
            return <>
                {stringJp.txt_md_change_to_bronze_1}
                {breakLine}
                {stringJp.txt_md_change_to_bronze_2}
            </>
    }

    const handleExit = () => {
        setVisible(false)
    }

    return (
        <Modal
            title=""
            className="md-warning-up-down-rank"
            centered
            open={visible}
            onCancel={handleExit}
            // onCancel={!isCheck ? handleExit : null} when maskClosable={true}
            footer={null}
            closable={false}
            width={345}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-confirm-wrapper">
                    <div className="icon-status-confirm">
                        <ExclamationOutlined className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">{stringJp.title_md_up_down_rank}</p>
                    <p className="md-warning">{stringJp.msg_warning_up_down}</p>
                    <p className="md-message-warning">
                        {stringJp[`${getCompanyCodeParam()}`].txt_rank_member}
                        {']'}
                        {windowWidth < 768 ? <br /> : ' '}
                        {stringJp.txt_md_change_rank_2}
                        <br />
                        {renderContent()}
                        <br />
                        {stringJp.txt_md_change_rank_3}
                    </p>

                    <Checkbox checked={isCheck}
                        onChange={e => setIsCheck(e.target.checked)}
                        className='chk-agree-out-rank'
                    >
                        {stringJp.chk_agree_up_down}
                    </Checkbox>
                </div>

                <div className="btn-group-container">
                    <div
                        className="btn-confirm"
                        style={{ opacity: isCheck ? 1 : 0.6 }}
                        onClick={isCheck ? handleExit : null}
                    >
                        {stringJp.btn_agree}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalUpDownRank; 
// API Endpoints
const GET_CREDIT_CARD_STATUS = '/ext/SearchAccountCreditCard';
const SEND_PHONE_NUMBER = '/ext/SmsIssue';
const SEND_SMS_CODE = '/ext/SmsAuth';

const GET_MEMBER_RANKS = '/ext/GetAccountRankHistory';
const GET_LIST_RANKS = '/ext/SearchAccountRankGroup';

const OUT_RANKING = '/ext/LeaveAccountRank';
const JOIN_FREE_RANK = '/ext/RegistAccountRank';
const JOIN_RANK = '/ext/SettlementAndRegistAccountRank';

const GET_PAYMENT_METHOD = '/ext/GetAccountRankPaymentMethod';
const GET_PAYPAY_URL = '/ext/GetPayPayRequestAccountRank';
const CHECK_PAYPAY_PAYMENT_STATUS = '/ext/CheckPaymentStatus';

export {
    GET_CREDIT_CARD_STATUS,
    SEND_PHONE_NUMBER,
    SEND_SMS_CODE,
    GET_MEMBER_RANKS,
    GET_LIST_RANKS,
    OUT_RANKING,
    JOIN_RANK,
    JOIN_FREE_RANK,
    GET_PAYMENT_METHOD,
    GET_PAYPAY_URL,
    CHECK_PAYPAY_PAYMENT_STATUS
}
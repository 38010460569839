import React from 'react'
import stringJp from '../../constants/string'
import MainLayout from '../../layout/MainLayout'
import { company } from '../../constants/configCompany'
import './ContactScreen.scss'

function ContactScreen() {
    return (
        <MainLayout className="contact-screen"
            screenTitle={stringJp.title_screen_contact}
        >
            <iframe src={company.EMAIL_CONTACT_URL}
                className='iframe-wrapper'
                frameBorder="0" loading='lazy' />
        </MainLayout>
    )
}

export default ContactScreen
import { Checkbox } from 'antd'
import React from 'react'
import stringJp from '../../../constants/string'
import { useWindowSize } from '../../../contexts'
import { getCompanyCodeParam } from '../../../contexts/functions'
import "./indexConfirmModal.scss"

function Step1({ isCheck, setIsCheck }) {
    const [windowWidth] = useWindowSize()

    return (
        <>
            <p className="md-title">
                {stringJp.title_modal_warning_out_rank}
            </p>
            {windowWidth < 768 ? (
                <>
                    <p className="md-message-warning">
                        {stringJp[`${getCompanyCodeParam()}`].txt_rank_member}
                        {stringJp[`${getCompanyCodeParam()}`].msg_warning_out_rank_1}
                    </p>
                    <p className="md-message-warning">
                        {stringJp.msg_warning_out_rank_2}
                    </p>
                </>
            ) : (
                <p className="md-message-warning">
                    {stringJp[`${getCompanyCodeParam()}`].txt_rank_member}
                    {stringJp[`${getCompanyCodeParam()}`].msg_warning_out_rank_1}
                    {stringJp.msg_warning_out_rank_2}
                </p>
            )}
            <ul className='list-msg'>
                <li>
                    <p className="md-message-warning">
                        {stringJp.msg_warning_out_rank_3a}
                    </p>
                </li>
                <li>
                    <p className="md-message-warning">
                        {stringJp.msg_warning_out_rank_3b}
                    </p>
                </li>
                <li>
                    <p className="md-message-warning">
                        {stringJp[`${getCompanyCodeParam()}`].msg_warning_out_rank_3c}
                    </p>
                </li>
            </ul>

            <Checkbox checked={isCheck}
                onChange={e => setIsCheck(e.target.checked)}
                className='chk-agree-out-rank'
            >
                {stringJp.txt_out_rank_agreement}
            </Checkbox>
        </>
    )
}

export default Step1
import React, { useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { Input, Typography, message } from 'antd'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillTelephoneFill } from 'react-icons/bs'

import { LcStorage } from '../../utils/storage'
import { HOME_SCREEN } from '../../constants/path'
import stringJp from '../../constants/string'
import {
    STORE_CODE, IN_STORE_CODE,
    ACCOUNT_ID, COMPANY_CODE, SYSTEM_ID,
    ACCOUNT_NAME, AUTH_CODE_LENGTH, HAD_CREDIT_CARD,
    AGREE_POLICY, CHECKBOX_HAD_CREDIT,
    MY_RANK, PAST_RANKS, SELECTION_STATE, IS_SUCCESS
} from '../../constants'

import { useAuth } from '../../contexts'
import {
    getCreditCardStatus, sendPhoneNumber, sendSMSCode
} from '../../contexts/actions/registers'
import {
    checkInternetWorking, convertSearchParamsIntoObj
} from '../../contexts/functions'
import MainLayout from '../../layout/MainLayout'
import { ButtonRegister, ModalCreditCardStatus } from '../../components'
import './indexRegisterScreen.scss'

const { Text } = Typography;

const HeaderRegister = (props) => {
    const { isLocation, setIsLocation, setErrorMsg } = props

    const handleBack = () => {
        setErrorMsg('')

        if (isLocation.screenSendPhoneNumber) {
            // Exit Loyalty webview. Redirect to app Scan&Go
            window.location.assign(process.env.REACT_APP_RANKS_DEEP_LINK)
            return
        }

        setIsLocation({
            screenSendSMSCode: false,
            screenSendPhoneNumber: true,
        })
    }

    return (
        <div className="item-content-header">
            <BiArrowBack
                className='header-button-back'
                onClick={handleBack}
            />
            <p className="screen-header">
                {stringJp.title_header_register_screen}
            </p>
        </div>
    )
}

const ContentRender = (props) => {
    const {
        title, inputCustom,
        valueInput, handleOnclick,
        isLocation, reSendPhoneNumber,
        errorMsg, isOnline, loadingBtn
    } = props

    const divRef = useRef(null)

    document?.getElementById('end-div')?.scrollIntoView({
        block: "end", behavior: "smooth"
    })

    const handleResendPhoneNumber = () => {
        reSendPhoneNumber(true)
    }

    return (
        <div className='body-wrapper'>
            <div className="item-content-body">
                <div className={clsx('text-title-screen', {
                    'screen-phone-number': isLocation.screenSendPhoneNumber
                })}>
                    {isLocation.screenSendSMSCode ? (
                        <>
                            {stringJp.title_screen_send_sms_code_1}
                            <br />
                            {stringJp.title_screen_send_sms_code_2}
                        </>
                    ) : title}
                </div>

                <div className="text-err-msg">
                    {isOnline ? (errorMsg || '') : ''}
                </div>

                {inputCustom()}
            </div>
            <div className="container-footers">
                {isLocation.screenSendSMSCode && (
                    <div className="text-message-regis">
                        <p>{stringJp.msg_send_sms_code}</p>
                        <div>{stringJp.msg_has_not_received_sms_code}{
                            <Text
                                onClick={handleResendPhoneNumber}
                                style={{ color: '#5EBBD8' }}>
                                {stringJp.btn_link_resend_sms_code}
                            </Text>
                        }</div>
                    </div>
                )}
            </div>
            <ButtonRegister
                loadingBtn={loadingBtn}
                valueInput={valueInput}
                lengthCheck={isLocation.screenSendPhoneNumber ? 10 : 6}
                titleButton={
                    isLocation.screenSendPhoneNumber
                        ? stringJp.title_btn_send_phone
                        : stringJp.title_btn_send_sms_code
                }
                handleClick={handleOnclick}
            />
            <div style={{ width: '100%', height: '20px' }} ref={divRef} id='end-div' />
        </div>
    )
}

const InputPhoneNumber = (props) => {
    const { phoneNumber, setPhoneNumber, onSubmit, setErrorMsg } = props

    const handleChangeInput = event => {
        const inputValue = event?.target?.value

        if (inputValue?.length <= 11)
            setPhoneNumber(inputValue)
        else
            setPhoneNumber(inputValue?.substring(0, 11))

        setErrorMsg('')
    }

    const handleKeyUp = () => {
        if (phoneNumber?.length > 10)
            document.getElementById('input-phone-number').blur()
    }

    const handleKeyPress = event => {
        // return event?.charCode >= 48 && event?.charCode <= 57
        const keyCode = event?.code

        if (['Minus', 'NumpadSubtract', 'NumpadAdd',
            'Period', 'NumpadDecimal'].includes(keyCode))
            event?.preventDefault()
    }

    return (
        <Input
            id='input-phone-number'
            className='input-phone-number-auth'
            type='number' pattern="\d*"
            prefix={<BsFillTelephoneFill color='#5EBBD8' size={18} />}
            maxLength={11} htmlSize={11}
            value={phoneNumber}
            onChange={handleChangeInput}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            onPressEnter={onSubmit}
            allowClear={true}
            bordered={false}
        />
    )
}

const InputSMScode = (props) => {
    const { codeSMS, setCodeSMS, onSubmit, setErrorMsg } = props

    /* 'codeSMS' is value of auth code which will be sent to server.
        
    * <InputSMSCode /> is a collection of 6 independently inputs,
        so each input own 1 digit in codeSMS.

    * If one digit is deleted, 1 space (' ') will be replaced in that position.

    * Every time a digit is filled, the cursor moves to the next input.
      If the cursor is in the last input, it will auto blur and close keyboard. 
    */
    let tempCodeSMS = codeSMS ? codeSMS?.split('') : Array.from(' '.repeat(6))

    const handleChange = (value, index) => {
        setErrorMsg('')
        if (index > 5) return
        // if (codeSMS?.replaceAll(/\s/g, '')?.length > 5) return

        if (value)
            tempCodeSMS[index] = value
        else
            tempCodeSMS[index] = ' '

        setCodeSMS([...tempCodeSMS]?.join('')?.substring(0, 6))
    }

    const handleClear = inputIndex => {
        if (inputIndex > 0)
            document.getElementById(`input-sms-code-${inputIndex - 1}`).focus()
    }

    const handleKeyUp = (event, index) => {
        const keyCode = event?.keyCode

        // 'Arrows' keycode: event.keyCode > 36 && event.keyCode < 41
        // 'Enter' keycode = 13          // 'Space bar' keycode = 32
        if (keyCode === 13 || keyCode === 32 || (keyCode > 36 && keyCode < 41))
            return

        // 'Backspace' keycode = 8       // 'Delete' keycode = 46
        if (keyCode === 8 || keyCode === 46) {
            handleClear(index)
            return
        }

        if (index > 5) return

        if (tempCodeSMS[index]?.trim() &&
            (tempCodeSMS[index]?.trim()?.length >= event?.target?.maxLength)) {
            document.getElementById(`input-sms-code-${index}`).blur()

            if (index < 5)
                document.getElementById(`input-sms-code-${index + 1}`).focus()
        }
    }

    const handleKeyPress = event => {
        if (['Minus', 'NumpadSubtract', 'NumpadAdd',
            'Period', 'NumpadDecimal', 'Equal'].includes(event?.code))
            event?.preventDefault()
    }

    return (
        <div className='input-sms-wrapper'>
            {[...Array(AUTH_CODE_LENGTH)].map((item, index) => (
                <Input
                    key={index}
                    autoFocus={index === 0}
                    bordered={false}
                    type='number' pattern="\d*"
                    id={'input-sms-code-' + index}
                    className='input-sms-code'
                    max={9} maxLength={1} htmlSize={1}
                    value={codeSMS[index]}
                    onChange={e => handleChange(e?.target?.value, index)}
                    onKeyUp={event => handleKeyUp(event, index)}
                    onKeyPress={handleKeyPress}
                    onPressEnter={onSubmit}
                />
            ))}
        </div>
    )
}

const RegisterScreen = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { setAuth, resetAuth, setUserData } = useAuth()

    const isNotShowAgain = LcStorage.getLocalJson(CHECKBOX_HAD_CREDIT)

    const [openMdCardStatus, setOpenMdCardStatus] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [codeSMS, setCodeSMS] = useState('')
    const [loadingBtn, setLoadingBtn] = useState(false)

    const [isLocation, setIsLocation] = useState({
        screenSendPhoneNumber: true,
        screenSendSMSCode: false,
    });

    const [errorMsg, setErrorMsg] = useState('')

    const [isOnline, setIsOnline] = useState(false)

    useEffect(() => {
        checkInternetWorking().then(value => {
            setIsOnline(value)
        })
    }, [])

    useEffect(() => {
        //LcStorage.clearLocal()
        LcStorage.removeLocal(HAD_CREDIT_CARD)
        LcStorage.removeLocal(AGREE_POLICY)
        LcStorage.removeLocal(SELECTION_STATE)
        LcStorage.removeLocal(ACCOUNT_NAME)
        LcStorage.removeLocal(MY_RANK)
        LcStorage.removeLocal(PAST_RANKS)
        LcStorage.removeLocal(IS_SUCCESS)
    }, [])

    useEffect(() => {
        resetAuth()

        const accountId = searchParams.get(ACCOUNT_ID)
        const companyCode = searchParams.get(COMPANY_CODE)
        const systemId = searchParams.get(SYSTEM_ID)
        const storeCode = searchParams.get(STORE_CODE)
        const inStoreCode = searchParams.get(IN_STORE_CODE)

        LcStorage.setLocalJson(IN_STORE_CODE, inStoreCode)

        if (accountId){
            LcStorage.setLocalJson(ACCOUNT_ID, accountId)
        }else{
            LcStorage.clearLocal()
        }
            
        if (companyCode){
            LcStorage.setLocalJson(COMPANY_CODE, companyCode)
        }else{
            LcStorage.clearLocal()
        }
            
        if (storeCode){
            LcStorage.setLocalJson(STORE_CODE, storeCode)
        }else{
            LcStorage.removeLocal(STORE_CODE)
        }

        if (inStoreCode){
            LcStorage.setLocalJson(IN_STORE_CODE, inStoreCode)
        }else{
            LcStorage.removeLocal(IN_STORE_CODE)
        }
            
        if (systemId){
            LcStorage.setLocalJson(SYSTEM_ID, systemId)
        }else{
            LcStorage.clearLocal()
        }
           
        if (isNotShowAgain?.accId === accountId && isNotShowAgain?.checked) {
            setOpenMdCardStatus(false)
            return
        }

        const params = { accountId, companyCode, systemId }

        const handleDataResponseGetSttCard = res => {
            if (res?.status === 200) {
                setErrorMsg('')
                const { registered = false, expired = false, usable = false } = res?.data

                if (registered && !expired && usable) {
                    setOpenMdCardStatus(false)
                    LcStorage.setLocalJson(HAD_CREDIT_CARD, true)
                }
                else {
                    setOpenMdCardStatus(true)
                    LcStorage.setLocalJson(HAD_CREDIT_CARD, false)
                }
            }
            else {
                setErrorMsg(res?.data?.message)
            }
        }

        getCreditCardStatus(params, handleDataResponseGetSttCard);
    }, [searchParams])

    const handelSendPhoneNumber = isNotifyNeeded => {
        setCodeSMS('')
        setErrorMsg('')
        setLoadingBtn(true)

        // Handle response data of API send SMS phone
        const handleDataRpSendPhoneNumber = res => {
            setLoadingBtn(false)
            if (res?.status === 200 && res?.data?.success) {
                setUserData(prevData => ({ ...prevData, phoneNum: phoneNumber }))

                if (isLocation.screenSendPhoneNumber)
                    setIsLocation({
                        screenSendSMSCode: true,
                        screenSendPhoneNumber: false,
                    })
                else if (isNotifyNeeded === true)
                    message.success(stringJp.msg_resent_done)
            }
            else
                setErrorMsg(res?.data?.message)
        }

        const formattedPhoneNum = phoneNumber?.startsWith('81')
            ? `+${phoneNumber}` : `+81${phoneNumber?.substring(1)}`

        sendPhoneNumber(handleDataRpSendPhoneNumber, formattedPhoneNum)
    }

    const handelSendSMSCode = () => {
        const handleDataRpSendSMSCode = res => {
            setLoadingBtn(false)
            setErrorMsg('')
            // Handle response data of API send SMS auth code
            if (res?.status === 200 && res?.data?.success) {
                setUserData(prevData => ({ ...prevData, codeSMS }))
                setAuth(codeSMS)

                const params = convertSearchParamsIntoObj(searchParams)
                navigate({
                    pathname: `/${HOME_SCREEN}`,
                    search: createSearchParams(params ? { ...params } : {}).toString()
                }, { replace: true })
            }
            else
                setErrorMsg(res?.data?.message)
        }

        setLoadingBtn(true)
        sendSMSCode(handleDataRpSendSMSCode, codeSMS)
    }

    return (
        <MainLayout className='register-screen'>
            <HeaderRegister
                isLocation={isLocation}
                setErrorMsg={setErrorMsg}
                setIsLocation={setIsLocation}
            />

            {(isLocation.screenSendPhoneNumber
                && !isLocation.screenSendSMSCode) ?
                <>
                    <ContentRender
                        loadingBtn={loadingBtn}
                        title={stringJp.title_screen_send_phone_number}
                        inputCustom={() => (
                            <InputPhoneNumber
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                onSubmit={() => handelSendPhoneNumber(false)}
                                setErrorMsg={setErrorMsg}
                            />
                        )}
                        valueInput={phoneNumber}
                        handleOnclick={handelSendPhoneNumber}
                        isLocation={isLocation}
                        errorMsg={errorMsg}
                        isOnline={isOnline}
                    />
                    <ModalCreditCardStatus
                        visible={openMdCardStatus}
                        setVisible={setOpenMdCardStatus}
                    />
                </>
                : <ContentRender
                    title=''
                    loadingBtn={loadingBtn}
                    inputCustom={() => (
                        <InputSMScode
                            codeSMS={codeSMS}
                            setCodeSMS={setCodeSMS}
                            onSubmit={handelSendSMSCode}
                            setErrorMsg={setErrorMsg}
                        />)
                    }
                    valueInput={codeSMS}
                    handleOnclick={handelSendSMSCode}
                    isLocation={isLocation}
                    reSendPhoneNumber={handelSendPhoneNumber}
                    errorMsg={errorMsg}
                    isOnline={isOnline}
                />
            }
        </MainLayout>
    )
}

export default RegisterScreen

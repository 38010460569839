import React from 'react'
import { company } from '../../../constants/configCompany'
import stringJp from '../../../constants/string'
import './ButtonCall.scss'

function ButtonCall({ label }) {

    return (
        <a href={`tel:${company.PHONE_NUMBER}`}
            className='btn-call'
        >
            {label ?? stringJp.title_btn_contact_via_call}
            <span>{stringJp.txt_accept_call_time}</span>
        </a>
    )
}

export default ButtonCall
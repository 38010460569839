import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { Radio, Space, Divider } from "antd";
import { LcStorage } from "../../utils/storage";
import {
  IconCreditCard,
  IconPayPay,
  IconPoint,
  IconMoney,
} from "../../assets/img";
import { getCreditCardStatus } from "../../contexts/actions/registers";
import { useWindowSize } from "../../contexts";
import {
  checkInternetWorking,
  getRankImgSrc,
  getRankName,
  getScreenOrModalTitle,
  getCompanyCodeParam,
  getSelectionState,
  convertSearchParamsIntoObj,
  getStoreCodeLocal,
  getInStoreCodeLocal
} from "../../contexts/functions";
import { useRank } from "../../contexts/ranks/RankContext";
import {
  checkPayPayPaymentStatus,
  getPayPayRequestUrl,
  joinOrUpdateRank,
} from "../../contexts/actions/memberInfo";

import stringJp from "../../constants/string";
import { COMPANY_CODES } from "../../constants/configCompany";
import {
  ACTION,
  MY_RANK,
  PAYPAY_ORDER_ID,
  SELECTION_STATE,
  AGREE_POLICY,
  IS_SUCCESS,
} from "../../constants";
import {
  PAYMENT_SCREEN,
  HOME_SCREEN,
  POLICY_SCREEN,
  ERROR_SCREEN,
} from "../../constants/path";
import MainLayout from "../../layout/MainLayout";
import {
  StyledButton,
  openModalNoInternet,
  ModalStatusPayment,
  ModalRecheckBill,
} from "../../components";
import "./indexPaymentScreen.scss";

const BtnPaymentMethod = (props) => {
  const { label, icon, errMsg, isEnable, onClick } = props;

  return (
    <div className="btn-payment-method-wrapper">
      <div
        className="btn-payment-method"
        style={{
          opacity: isEnable ? 1 : 0.6,
          borderColor: isEnable ? "#5ebbd8" : "rgba(0, 0, 0, 0.25)",
          cursor: isEnable ? "pointer" : "not-allowed",
        }}
        onClick={isEnable ? onClick : null}
      >
        {icon}
        <p style={{ color: isEnable ? "#5ebbd8" : "rgba(0, 0, 0, 0.25)" }}>
          {label}
        </p>
      </div>

      <p className="txt-payment-err">{errMsg || ""}</p>
    </div>
  );
};

function PaymentScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [windowWidth] = useWindowSize();
  const currentLocation = useLocation();
  const { state, search: queryParams } = currentLocation;
  const {
    selectedRank,
    setSelectedRank,
    pointBalance,
    setPointBalance,
    paymentMethods,
    setPaymentMethods,
  } = useRank();
  const [rdOption, setRdOption] = useState(1);
  const [pointsInput, setPointsInput] = useState(0);
  const params = convertSearchParamsIntoObj(searchParams);
  const [totalPoints, setTotalPoints] = useState(pointBalance);
  const [usedPoints, setUsedPoints] = useState(0);
  const [totalFee, setTotalFee] = useState(selectedRank?.admission_fee ?? 0);
  const [isOpenSelectBox, setIsOpenSelectBox] = useState(false);
  const [openModalPaymentFailed, setOpenModalPaymentFailed] = useState(false);
  const [openModalRecheckBill, setOpenModalRecheckBill] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [isFromPayPay, setIsFromPayPay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsOnline] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [registered, setRegistered] = useState(false);
  const selectionState = getSelectionState();
  const [isMoneyPoint, setIsMoneyPoint] = useState(false);

  // tam dung
  const store_code = getStoreCodeLocal()
  const instore_code = getInStoreCodeLocal()

  const checkStoreCodeAndInStoreCode = () => {
    if ((store_code === "09659" && instore_code === "6101") || (store_code === "09136" && instore_code === "6101")) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    checkInternetWorking().then((value) => {
      setIsOnline(value);
      if (!value) openModalNoInternet();
    });

    if (+LcStorage.getLocalJson(IS_SUCCESS) === 1) {
      // updateLastLocation(currentLocation.pathname)
      navigate(`/${ERROR_SCREEN}`); // , { replace: true }
    }
  }, []);

  useEffect(() => {
    if (params.account_id && params.company_code && params.system_id) {
      getCreditCardStatus(params, handleRpGetCreditCard);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!queryParams) return;

    if (!LcStorage.getLocalJson(AGREE_POLICY)) {
      navigate(
        {
          pathname: `/${POLICY_SCREEN}`,
          search: createSearchParams({
            action: searchParams.get(ACTION) || "",
          }).toString(),
        },
        { replace: true }
      );
    }

    if (selectedRank) {
      LcStorage.setLocalJson(SELECTION_STATE, {
        rank: selectedRank,
        points: pointBalance,
        method: paymentMethods,
        action: searchParams.get(ACTION),
      });
      return;
    }

    if (!selectionState) {
      navigate(`/${HOME_SCREEN}`, { replace: true });
      return;
    }

    setSelectedRank(selectionState?.rank);
    setTotalPoints(selectionState?.points);
    setPointBalance(selectionState?.points);
    setPaymentMethods(selectionState?.method);
    setTotalFee(selectionState?.rank?.admission_fee);
  }, [queryParams]);

  useEffect(() => {
    if (!queryParams) return;

    // Redirected from PayPay payment
    if (!searchParams?.get("paypay")) return;
    // This is redirected from PayPay
    // updateLastLocation(currentLocation.pathname)

    switch (searchParams?.get("paypay")) {
      case "success":
        const orderId = LcStorage.getLocalJson(PAYPAY_ORDER_ID);
        setIsLoading(true);

        const handleDataResponse = (res) => {
          if (
            res?.status === 200 &&
            res?.data?.success &&
            res?.data?.status === "paid"
          ) {
            LcStorage.removeLocal(PAYPAY_ORDER_ID);
            LcStorage.setLocalJson(IS_SUCCESS, 1);

            navigate(
              {
                pathname: `/success`,
                search: createSearchParams({
                  action: searchParams.get(ACTION) || "",
                }).toString(),
              },
              { replace: true }
            );
          } else setOpenModalPaymentFailed(true);

          setIsLoading(false);
        };

        checkPayPayPaymentStatus(handleDataResponse, orderId);
        break;

      case "failure":
        setIsFromPayPay(true);
        setOpenModalPaymentFailed(true);
        break;

      default:
        break;
    }
  }, [queryParams]);

  useEffect(() => {
    if (totalFee > 0) {
      setIsMoneyPoint(
        Math.abs(usedPoints) + paymentMethods.pointMoneyBalance >=
          selectedRank?.admission_fee
      );
    } else {
      setIsMoneyPoint(false);
    }
  }, [usedPoints, paymentMethods, selectedRank, totalFee]);

  const handleRpGetCreditCard = (res) => {
    if (res?.status === 200) {
      // setErrorMsg('')
      const { registered = false, expired = false, usable = false } = res?.data;

      if (registered && !expired && usable) {
        setRegistered("SUCCESS");
      } else {
        setRegistered("ERROR");
      }
    } else {
      console.log(res?.data?.message);
      // setErrorMsg(res?.data?.message)
    }
  };

  const handleOpenPointsUsingSelection = () => {
    setIsOpenSelectBox(true);
    setUsedPoints(0);
    setTotalPoints(pointBalance);
    setTotalFee(selectedRank?.admission_fee ?? 0);
  };

  const handleChangeRdOption = (option) => {
    setRdOption(option);
    if (option !== 2) setPointsInput(0);
  };

  const handleChangePointInput = (value) => {
    /** Rules:
     *  - Only accept inputted value which is not greater than
     *    total available points or admission fee
     *  - Remove all leading zeros
     */

    if (
      (totalPoints >= totalFee && +value <= totalFee) ||
      (totalPoints <= totalFee && +value <= totalPoints)
    ) {
      // setPointsInput(value.replace(/[^0-9]/g, ""))

      let inputValuePoint = value.replace(/[^0-9]/g, "");
      setPointsInput(inputValuePoint?.replace(/^0+/, ""));
    }
  };

  const handleApplyPoints = () => {
    if (rdOption === 2) {
      setUsedPoints(-pointsInput);
      setTotalFee(totalFee - +pointsInput);
      setTotalPoints(totalPoints - +pointsInput);
    } else if (rdOption === 3) {
      setUsedPoints(totalPoints > totalFee ? -totalFee : -totalPoints);
      setTotalPoints(totalPoints > totalFee ? totalPoints - totalFee : 0);
      setTotalFee(totalPoints > totalFee ? 0 : totalFee - totalPoints);
    }
    setIsOpenSelectBox(false);
  };

  const handlePayByPointOrCard = (value) => {
    setIsLoading(true);
    const reqData = {
      rankId: selectedRank?.account_rank_group_id,
      didUsePoint: Math.abs(usedPoints) > 0,
      usedPointsAmount: Math.abs(usedPoints),
      usedValueCard:
        value !== "pointMoney" ? false : paymentMethods.paymentPointMoney,
    };
    const handleDataRes = (res) => {
      setLoadingBtn(false);
      if (res?.status === 200 && res?.data?.success) {
        LcStorage.setLocalJson(MY_RANK, selectedRank);
        LcStorage.setLocalJson(IS_SUCCESS, 1);
        navigate(`/success`, { replace: true });
      } else setOpenModalPaymentFailed(true);

      setIsLoading(false);
    };
    setLoadingBtn(true);
    joinOrUpdateRank(handleDataRes, reqData);
  };

  const handlePayByPayPay = () => {
    setIsLoading(true);

    const locationOrigin = window?.location?.origin;

    const reqData = {
      rankId: selectedRank?.account_rank_group_id,
      didUsePoint: Math.abs(usedPoints) > 0,
      usedPointsAmount: Math.abs(usedPoints),
      successURL: `${locationOrigin}/pending?paypay=success`,
      cancelURL: `${locationOrigin}/${PAYMENT_SCREEN}?paypay=cancel`,
      errorURL: `${locationOrigin}/${PAYMENT_SCREEN}?paypay=failure`,
    };

    const handleDataResponsePayPay = (res) => {
      setLoadingBtn(false);
      if (res?.status === 200 && res?.data?.success) {
        const { paypay_url: paypayURL } = res?.data;

        if (!paypayURL?.trim()) return;
        // LcStorage.setLocalJson(IS_SUCCESS, 1)

        try {
          const temp = paypayURL?.substring(paypayURL?.indexOf("orderId="));
          const orderId = temp?.substring(
            temp?.indexOf("=") + 1,
            temp?.indexOf("&")
          );

          LcStorage.setLocalJson(PAYPAY_ORDER_ID, orderId);

          navigate(
            "/pending",
            {
              state: { paypayURL: paypayURL },
            },
            { replace: true }
          );

          // window.open(paypayURL, '_self', 'noopener,noreferrer')
        } catch (error) {
          console.log(
            "Error at handleDataResponse() of getPayPayRequestUrl():",
            error
          );
        }
      } else setOpenModalPaymentFailed(true);

      setIsLoading(false);
    };

    setLoadingBtn(true);
    getPayPayRequestUrl(handleDataResponsePayPay, reqData);
  };

  const isCreditCardEnable = () => {
    return (
      !state?.isFailure && paymentMethods?.creditCard?.usable && totalFee > 0
    );
    // && paymentMethods?.creditCard?.registered
  };

  const rankTitleFee =
    getCompanyCodeParam() === COMPANY_CODES.KASUMI ? (
      `
            ${getRankName(
              selectedRank?.account_rank_group_id,
              `/${PAYMENT_SCREEN}`
            )}
            ${stringJp.txt_annual_fee}
        `
    ) : (
      <>
        {selectedRank?.account_rank_group_name}
        {windowWidth < 400 && windowWidth > 320 ? <br /> : " "}
        {stringJp.txt_annual_fee}
      </>
    );

  const screenTitle = searchParams?.get("paypay")
    ? getScreenOrModalTitle(selectionState?.action)
    : getScreenOrModalTitle(searchParams.get(ACTION));

  function pointBalances() {
    return (
      <>
        <Divider className="divider" />

        <div className="content-group-wrapper">
          <div className="txt-payment">
            <img className="img-points" src={IconPoint} alt="" />
          </div>
          <p className="txt-points-balance">{stringJp.txt_points_balance}</p>

          <div
            className="txt-payment using-points"
            style={{ margin: "-16px 0px 12px 0px" }}
          >
            <div className="txt-points-wrapper">
              <div className="txt-points">
                {totalPoints}
                <div>{stringJp.txt_equivalent_to_yen}</div>
              </div>
            </div>

            {!isOpenSelectBox && (
              <StyledButton
                label={stringJp.txt_points_using}
                onClick={handleOpenPointsUsingSelection}
                className="btn-use-points"
              />
            )}
          </div>
        </div>

        {isOpenSelectBox && (
          <div className="rd-using-points-wrapper">
            <div style={{ padding: "0 32px 0" }}>
              <Radio.Group
                onChange={(e) => handleChangeRdOption(e?.target?.value)}
                value={rdOption}
              >
                <Space direction="vertical">
                  <Radio value={1}>{stringJp.radio_btn_not_use_point}</Radio>
                  <Radio value={2}>
                    <input
                      className="rd-input-custom-point"
                      value={pointsInput}
                      onChange={(e) => handleChangePointInput(e?.target?.value)}
                      disabled={rdOption !== 2}
                    />
                    {stringJp.radio_btn_use_points}
                  </Radio>
                  <Radio value={3}>{stringJp.radio_btn_use_all_points}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="btn-apply-wrapper">
              <StyledButton
                label={stringJp.title_btn_confirm}
                onClick={handleApplyPoints}
                className="btn-apply"
              />
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <MainLayout
      className="payment-screen"
      screenTitle={screenTitle}
      color={"#ffffff"}
      isLoading={isLoading}
    >
      <div id="payment-container" className="screen-body-container">
        <div className="content-group-wrapper">
          <div className="rank-fee-header" style={{ marginBottom: 10 }}>
            <b className="txt-rank-fee">{rankTitleFee}</b>
            <div className="img-rank">
              <img
                alt="current-rank"
                src={getRankImgSrc(selectedRank?.account_rank_group_id)}
              />
            </div>
          </div>

          <div className="txt-payment">
            <p>{stringJp.txt_total}</p>
            <p>
              {stringJp.txt_symbol_yen + (selectedRank?.admission_fee ?? 0)}
            </p>
          </div>
          <div className="txt-payment">
            <p> {stringJp.txt_points_using} </p>
            <p className="txt-no-points">
              {usedPoints === 0 ? stringJp.txt_not_use_point : usedPoints}
            </p>
          </div>
          <div className="txt-payment">
            <p className="txt-total-payment title">
              {stringJp.txt_total_payment}
            </p>
            <p className="txt-total-payment">
              <b>
                {stringJp.txt_symbol_yen +
                  (totalFee ?? selectedRank?.admission_fee ?? 0)}
              </b>
            </p>
          </div>
        </div>

        {selectionState?.method?.grantPoint === "ignica" ? (
          pointBalances()
        ) : selectionState?.method?.grantPoint === "tpoint" ? (
          <></>
        ) : null}

        <div className="group-btn-payment-methods">
          {(getCompanyCodeParam() === COMPANY_CODES.KASUMI  || getCompanyCodeParam() === COMPANY_CODES.MARUETSU) &&
            !checkStoreCodeAndInStoreCode() &&
            selectionState?.method?.grantPoint === "ignica" &&
            selectionState?.method?.paymentPointMoney === true && 
            (
              <div className="t-money-container">
                <div style={{ marginBottom: 10 }}>
                  <div style={{ width: 72 }}>
                    <img src={IconMoney} alt="" width="100%" height="100%" />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <b style={{ color: "#2c367d", fontSize: 13.5 }}>
                      {"money残高  "}
                    </b>
                    <b style={{ color: "#FA8C16", fontSize: 17 }}>
                      {paymentMethods.pointMoneyBalance
                        ? paymentMethods.pointMoneyBalance
                        : 0}
                    </b>
                    <b style={{ color: "#FA8C16" }}>{" " + stringJp.txt_yen}</b>
                  </div>
                  {!isMoneyPoint && (
                    <div
                      style={{
                        display:
                          paymentMethods.pointMoneyBalance >=
                            selectedRank?.admission_fee && "none",
                      }}
                    >
                      <p
                        className="txt-payment-err"
                        style={{ fontSize: 13, marginBottom: 5 }}
                      >
                        {stringJp.txt_warning_money_point1}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: isMoneyPoint ? 20 : 10,
                  }}
                  className="btn-payment-method-wrapper"
                >
                  <div
                    className="btn-payment-method"
                    style={{
                      height: 50,
                      width: "65%",
                      borderColor: "#2c367d",
                      opacity: isMoneyPoint ? 1 : 0.3,
                      cursor: isMoneyPoint ? "pointer" : "not-allowed",
                    }}
                    onClick={
                      isMoneyPoint
                        ? () => handlePayByPointOrCard("pointMoney")
                        : null
                    }
                  >
                    <div style={{ width: 65 }}>
                      <img src={IconMoney} alt="" width="100%" height="100%" />
                    </div>
                  </div>
                </div>
              </div>
              // <div></div>
            )}
          <div style={{ padding: "0 32px 0" }}>
            {selectionState?.method?.grantPoint === "ignica" ? (
              <BtnPaymentMethod
                label={stringJp.title_btn_pay_by_point}
                icon={<img src={IconPoint} alt="" width="56" height="14" />}
                isEnable={!state?.isFailure && totalFee === 0}
                onClick={() => {
                  setClickedButton("Point");
                  setOpenModalRecheckBill(true);
                }}
              />
            ) : selectionState?.method?.grantPoint === "tpoint" ? (
              <></>
            ) : null}

            <BtnPaymentMethod
              label={stringJp.title_btn_pay_by_card}
              icon={<img src={IconCreditCard} alt="" width="21" height="16" />}
              isEnable={isCreditCardEnable() && registered === "SUCCESS"}
              // hasErr={!registered}
              errMsg={registered === "ERROR" && stringJp.err_pay_by_credit_card}
              onClick={() => {
                setClickedButton("Card");
                setOpenModalRecheckBill(true);
              }}
            />

            {paymentMethods?.payPay && (
              <BtnPaymentMethod
                label={stringJp.title_btn_pay_by_PayPay}
                icon={<img src={IconPayPay} alt="" width="24" height="24" />}
                isEnable={!state?.isFailure && totalFee > 0}
                onClick={() => {
                  setClickedButton("PayPay");
                  setOpenModalRecheckBill(true);
                  localStorage.removeItem("openPayPay");
                }}
              />
            )}
          </div>
        </div>
      </div>

      {openModalPaymentFailed && (
        <ModalStatusPayment
          visible={openModalPaymentFailed}
          setVisible={setOpenModalPaymentFailed}
          isFromPayPay={isFromPayPay}
        />
      )}

      {openModalRecheckBill && (
        <ModalRecheckBill
          loadingBtn={loadingBtn}
          visible={openModalRecheckBill}
          setVisible={setOpenModalRecheckBill}
          totalFee={totalFee}
          setClickedButton={setClickedButton}
          handlePayment={
            clickedButton === "PayPay"
              ? handlePayByPayPay
              : handlePayByPointOrCard
          }
        />
      )}
    </MainLayout>
  );
}

export default PaymentScreen;

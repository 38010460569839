import HttpInstance from "../../services/AxiosInstance"
import { getAccountIdParam, getCompanyCodeParam, getSystemIdParam } from "../functions"
import {
    GET_CREDIT_CARD_STATUS,
    SEND_PHONE_NUMBER,
    SEND_SMS_CODE
} from "../../constants/hrefApp"

const getCreditCardStatus = (params, handleDataRp) => {
    const dataBodyReq = {
        "account_id": params?.accountId
            || getAccountIdParam() || '',
        "company_code": params?.getCompanyCodeParam
            || getCompanyCodeParam() || '',
        "system_id": params?.systemId
            || getSystemIdParam() || ''
    }

    HttpInstance()
        .post(GET_CREDIT_CARD_STATUS, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
};

const sendPhoneNumber = (handleDataRp, phoneNumber) => {
    const dataBodyReq = {
        "account_id": getAccountIdParam(),
        "sms": phoneNumber
    }

    HttpInstance()
        .post(SEND_PHONE_NUMBER, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response)
        });
};

const sendSMSCode = (handleDataRp, smsAuth) => {
    const dataBodyReq = {
        "account_id": getAccountIdParam(),
        "sms_auth": smsAuth
    }

    HttpInstance()
        .post(SEND_SMS_CODE, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
};

export {
    getCreditCardStatus,
    sendPhoneNumber,
    sendSMSCode
};